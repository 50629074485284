<template>
  <div class="shop-shop-contents">
    <div class="top">
      <img alt="really-goodトップ" :src="`${rootUrl}${shopData.topImagePath}`" class="pc" />
      <img alt="really-goodトップ" :src="`${rootUrl}${shopData.spTopImagePath}`" class="sp" />
    </div>

    <div class="back" :style="`background-image: url(${rootUrl}/img/reallygood-top-bottom.png);`">
      <HomeGallery />
    </div>

    <div class="wrap960 ifyou">
      <HomeIfYouBuy :shopId="shopId" />
    </div>

    <div style="background-color: #fff9e9" class="spcae3 link" id="purchase">
      <div class="wrap960">
        <HomeHowToBuy :shopId="shopId" />
      </div>
    </div>

    <div id="aboutus">
      <HomeAboutUs :displayShopData="false" />
    </div>

    <div class="fixed-back space-150" :style="`padding: 5em 0;background-image: url(${rootUrl}/img/back-img02.jpg);`">
      <HomeReason />
    </div>

    <div class="space-150 wrap960">
      <HomePromise />
    </div>
  </div>
</template>

<script>
import { mapGetters } from "vuex";
import ShopConsts from "@/consts/ShopConsts";
import HomeGallery from "@/components/Home/Gallery.vue";
import HomeIfYouBuy from "@/components/Home/IfYouBuy.vue";
import HomeHowToBuy from "@/components/Home/HowToBuy.vue";
import HomeAboutUs from "@/components/Home/AboutUs.vue";
import HomeReason from "@/components/Home/Reason.vue";
import HomePromise from "@/components/Home/Promise.vue";

export default {
  name: "shop-contents",
  components: {
    HomeGallery,
    HomeIfYouBuy,
    HomeHowToBuy,
    HomeAboutUs,
    HomeReason,
    HomePromise,
  },
  props: {
    shopId: String
  },
  data() {
    return {
      // Consts
      ShopConsts: ShopConsts,
    };
  },
  computed: {
    ...mapGetters(["rootUrl"]),
    shopData() {
      // 
      let shopData = null;

      // 
      switch (this.shopId) {
        // 
        case ShopConsts.ID_NAGOYA:
          shopData = {
            topImagePath: "/img/nagoya-top.jpg",
            spTopImagePath: "/img/nagoya-top-sp.jpg",
          };
          break;

        // 
        case ShopConsts.ID_OKAZAKI:
          shopData = {
            topImagePath: "/img/okazaki-top.jpg",
            spTopImagePath: "/img/okazaki-top-sp.jpg",
          };
          break;

        default:
          // 
          break;
      }

      // 
      return shopData;
    },
  },
  methods: {},
};
</script>

<style lang="scss" scoped>
.top {
  margin: 0 auto;
  max-width: 1100px;
  width: 100vw;

  @media screen and (max-width: 768px) {
    width: 100%;
    margin: 20px 0;
  }

  .pc {
    width: 95%;
    margin-top: 70px;

    @media screen and (max-width: 768px) {
      display: none;
    }
  }

  .sp {
    display: none;

    @media screen and (max-width: 768px) {
      display: initial;
      width: 100%;
    }
  }
}

.fixed-back {
  position: relative;
  // min-height: 100%;
  // height: 1350px; /*--スクロールをさせるため 高さ指定--*/
  background-size: cover;
  /*--背景画像のサイズ--*/
  // background-attachment: fixed; /*--背景画像の固定--*/
  background-repeat: no-repeat;
  /*--背景画像の繰り返し--*/
  background-position: center center;
  /*--背景画像の位置--*/
  z-index: 2;
  padding: 5em 0;

  @media screen and (max-width: 1024px) {
    background-attachment: initial;
    width: 100%;
    // background-size: auto 100%;
    // background-size:contain;
  }
}

#purchase {
  padding: 5em 0;
}

.back {
  padding-top: 10px;
  padding-bottom: 150px;
  background-size: cover;
  background-repeat: no-repeat;

  @media screen and (max-width: 768px) {
    // padding-top: 0;
    padding-bottom: 50px;
  }
}
</style>
