<template>
  <div class="ctrl-login">
    <div class="wrap1100">
      <CtrlArticle />
    </div>
    </div>
</template>

<script>
import { mapGetters } from "vuex";
import CtrlArticle from "@/components/Ctrl/Article.vue";
export default {
  name: "ctrl-login",
  components: {
    CtrlArticle,
  },
  props: {
    categoryId: String,
  },
  data() {
    return {};
  },
  computed: {
    ...mapGetters(["rootUrl"]),
  },
};
</script>

<style lang="scss" scoped>
</style>
