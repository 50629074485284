<template>
  <div class="ctrl-login">
    <div class="wrap">
      <CtrlMemberMembers :categoryId="categoryId"/>
    </div>
  </div>
</template>

<script>
import { mapGetters } from "vuex";
import CtrlMemberMembers from "@/components/Ctrl/Member/Members.vue";

export default {
  name: "ctrl-login",
  components: {
    CtrlMemberMembers,
  },
  props: {
    categoryId: String,
  },
  data() {
    return {};
  },
  computed: {
    ...mapGetters(["rootUrl"]),
  },
};
</script>

<style lang="scss" scoped>
</style>
