<template>
  <div class="ui-display-validation-error-messages">
    <div
      class="validation-messages"
      v-for="(message, index) in messageList"
      :key="index"
    >
      {{ message }}
    </div>
  </div>
</template>

<script>
import { mapGetters } from "vuex";
export default {
  name: "ui-display-validation-error-messages",
  components: {
  },
  props: {
    messageList: Array,
  },
  data() {
    return {};
  },
  computed: {
    ...mapGetters(["rootUrl"]),
  },
};
</script>

<style lang="scss" scoped>
.validation-messages {
  color: red;
  font-size: 14px;
}
</style>