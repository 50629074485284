<template>
  <div class="home-customers-voice">
    <div class="title-cover space-150">
      <img :src="`${rootUrl}/img/title04.png`" alt="お客様の声" />
    </div>
    <p class="center space-50 top">
      ご利用いただいたお客様の声をご紹介します。高価買取実績が多数あります。
    </p>
    <div class="box-voice-cover space-100">
      <div class="box-voice">
        <UiBoxVoice
          :title="`丁寧な対応と査定で、<br>安心してお願い出来ました`"
          :subTitle="`ロックのレコード500枚を220,000円で買取させて頂きました。`"
          :detail="`レコード買取のサービスは初めて利用しましたが、驚くほどスムーズで効率的でした。丁寧な査定と迅速な支払いに感動しました。また、親切なスタッフの方々が私の質問に快く答えてくれたので、安心してお取引できました。大変満足しています！`"
          :target="`愛知県 / 60代 / 男性`"
          class="box"
        />
        <UiBoxVoice
          :title="`車での持ち込み、その場で<br>すぐお見積もりしてくれました。`"
          :subTitle="`邦楽のレコード100枚を18,000円で買取させて頂きました`"
          :detail="`私の手元には不要なレコードがたくさんありましたが、どこに売ればいいのか迷っていました。幸いなことにリアリーグッドさんが近くにあることを知り、利用してみたところ、素晴らしい価格で買い取っていただけました。駐車券サービスがあり駐車場代もかかりませんでした。`"
          :target="`岐阜県 / 40代 / 男性`"
          class="box"
        />
      </div>

      <div class="box-voice">
        <UiBoxVoice
          :title="`いくつかの業者を試しましたが、<br>専門的で一番満足できました`"
          :subTitle="`洋楽・邦楽のレコード50枚を28,000円で買取させて頂きました。`"
          :detail="`レコード買取に関してはいくつかの業者を試しましたが、こちらは専門的で一番満足できました。査定の基準が明確で、公正な価格を提示してくれました。正直なところ自信のないレコードもありましたが、ちゃんと値段をつけてくれました。信頼できるお店に出会えて良かったです！`"
          :target="`神奈川県 / 30代 / 男性`"
          class="box"
        />

        <UiBoxVoice
          :title="`大切な思い出を共有できた事が<br>とても嬉しかった。`"
          :subTitle="`洋楽のレコード800枚を400,000円で買取させて頂きました`"
          :detail="`私のレコードコレクションは数十年にわたって育まれたもので、手放すことは寂しかったですが、一度見積りに来てくれるというので出張買取を頼みました。手早く見積もりを出していただき、金額も想定以上でしたので、そのまま買取を依頼しました。<br>また、スタッフの方々が私の思い入れのあるアーティストやアルバムについて熱心に話してくれたので、大切な思い出を共有できたことが嬉しかったです。`"
          :target="`愛知県 / 70代 / 男性`"
          class="box"
        />
      </div>
    </div>
  </div>
</template>

<script>
import { mapGetters } from "vuex";
import UiBoxVoice from "@/components/Ui/BoxVoice.vue";
export default {
  name: "home-if-you-buy",
  components: {
    UiBoxVoice,
  },
  props: {},
  data() {
    return {};
  },
  computed: {
    ...mapGetters(["rootUrl"]),
  },
};
</script>

<style lang="scss" scoped>
.home-customers-voice {
  padding-bottom: 100px;
  @media screen and (max-width: 768px) {
    padding-bottom: 30px;
  }
  .title-cover {
    img {
      margin-top: 100px;
      @media screen and (max-width: 768px) {
        margin-top: 50px;
      }
    }
  }
  .box-voice {
    display: flex;
    justify-content: space-between;
    align-items: flex-start;
    @media screen and (max-width: 1024px) {
      flex-direction: column;
    }

    .box {
      width: 49%;
      @media screen and (min-width: 581px) and (max-width: 1024px) {
        width: 70%;
        margin-left: auto;
        margin-right: auto;
      }
      @media screen and (max-width: 580px) {
        width: 100%;
      }
    }
  }
}
</style>