<template>
  <div class="ctrl-login">
    <div class="wrap">
      <CtrlMemberRegisterMember
        :categoryId="categoryId"
        :groupId="groupId"
        :memberId="memberId"
      />
    </div>
  </div>
</template>

<script>
import { mapGetters } from "vuex";
import CtrlMemberRegisterMember from "@/components/Ctrl/Member/RegisterMember.vue";
export default {
  name: "MemberDetail",
  components: {
    CtrlMemberRegisterMember,
  },
  props: {
    categoryId: String,
    groupId: String,
    memberId: String,
  },
  data() {
    return {};
  },
  computed: {
    ...mapGetters(["rootUrl"]),
  },
};
</script>

<style lang="scss" scoped>
</style>
