<template>
  <div class="ui-box-point">
    <div class="box-cover">
      <img :src="`${rootUrl}/img/${imgpath}`" :alt="name" />

      <div class="title">
        <p v-html="title"></p>
      </div>
      <p v-html="des" class="des"></p>
    </div>
  </div>
</template>

<script>
export default {
  name: "ui-box-point",
  components: {},
  props: {
    imgpath: String,
    name: String,
    title: String,
    des: String,
  },
  data() {
    return {};
  },
  computed: {
    rootUrl() {
      return this.$store.getters.rootUrl;
    },
  },
  methods: {},
};
</script>

<style lang="scss" scoped>
.ui-box-point {
  // margin-top: 100px;
  // background-color: white;
  padding: 70px;
  @media screen and (max-width: 768px) {
    padding: 20px;
  }
  .box-cover {
    display: flex;
    flex-direction: column;
    align-items: center;
    @media screen and (min-width: 581px) and (max-width: 768px) {
      width: 70%;
      margin-left: auto;
      margin-right: auto;
    }
    @media screen and (max-width: 580px) {
      width: 100%;
    }
    .title {
      font-size: 32px;
      font-weight: bold;
      border-bottom: 1px solid black;
      width: 100%;
      text-align: center;
      @media screen and (max-width: 768px) {
        font-size: 26px;
      }
      // margin-bottom: 10px;
    }
    img {
      height: 150px;
      margin-bottom: 80px;
    }
    .des {
      font-size: 19px;
      padding: 30px 0;
    }
  }
}
</style>
