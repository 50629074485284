<template>
  <div class="ui-item-box">
    <div class="item-box-cover">
      <div class="item-box">
        <div class="square_image">
          <img :src="`${rootUrl}/storage/${productData.imgPath}`" />
        </div>
        <div class="text-cover">
          <div class="title">
            <p>{{ productData.title }}</p>
          </div>

          <p class="des" v-html="productData.text"></p>
          <div class="contract">
            <p class="small">参考買取価格</p>
            <p class="price">&ensp;{{ productData.price | localeNum}}<span>円</span></p>
          </div>

        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "ui-article-item-box",
  components: {},
  props: {
    productId: String,
    categoryId: String,
  },
  data() {
    return {};
  },
  computed: {
    rootUrl() {
      return this.$store.getters.rootUrl;
    },
    productData() {
      return this.$store.getters.productData(this.categoryId, this.productId);
    },
  },
  methods: {},
};
</script>

<style lang="scss" scoped>
.ui-item-box {
  font-weight: 700;
  .item-box-cover {
    border: 1px solid black;
    display: flex;
    justify-content: space-between;
    margin-top: 30px;
    .item-box {
      width: 100%;
      .square_image {
        position: relative;

        img {
          position: absolute;
          width: 100%;
          height: 100%;
          top: 0;
          right: 0;
          bottom: 0;
          left: 0;
          margin: auto;
          object-fit: cover;
        }
      }
      .square_image::before {
        content: "";
        display: block;
        padding-top: 100%;
      }

      .text-cover {
        background-color: white;
        padding: 10px 10px 30px;
        text-align: left;
        .title {
          font-size: 16px;
          color: white;
          letter-spacing: 0.1em;
          display: flex;
          align-items: center;
          line-height: 1em;
          min-height: 58px;
          display: flex;
          align-items: flex-start;
            @media screen and (max-width: 768px) {
              font-size: 12px;
              min-height: 58px;
            }
          p {
            min-height: 42px;
            background-color: black;
            padding: 5px 15px;
            display: flex;
            align-items: center;
            @media screen and (max-width: 768px) {
              padding: 5px 3px;
            }
          }
        }
        .des {
          line-height: 1.6em;
          min-height: 144px;
          word-break: break-all;
          @media screen and (min-width:768px) and ( max-width:960px){
            min-height: 172px;
          }
          @media screen and (max-width: 768px) {
            min-height: 178px;
            // min-height: 0;
            font-size: 16px;
          }
        }
        .contract {
          color: #ff0000;
          margin-top: 20px;
          font-size: 32px;
          @media screen and (max-width: 768px) {
            font-size: 27px;
          }
          a:hover {
            opacity: 0.6;
          }

          .small {
            font-size: 18px;
          }
          .price{
            text-align: right;
            white-space: nowrap;
            @media screen and (max-width: 768px) {
              font-size: 26px;
              // margin-right: 30px;
              span{
                font-size: 20px;
              }
            }
          }
        }
        a {
          text-decoration: none;
          padding-left: 1px;
        }
        a:hover {
          opacity: 0.6;
        }
      }
    }
  }
}
</style>
