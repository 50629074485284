<template>
  <div class="layout-footer">
    <div class="footer footer-sp-bottom">
      <div class="footer-menu wrap960">
        <table>
          <tr>
            <td class="short"><router-link to="/">Top</router-link></td>
            <td class="short">
              <router-link to="/" v-scroll-to="`#strong`"
                >買取強化アイテム</router-link
              >
            </td>
            <td class="short">
              <router-link to="/" v-scroll-to="`#purchase`"
                >買取方法</router-link
              >
            </td>
            <td class="short">
              <router-link to="/" v-scroll-to="`#voice`"
                >お客様の声</router-link
              >
            </td>
            <td class="long">
              <router-link to="/contact"
                >メールでの買取依頼・お問い合わせ</router-link
              >
            </td>
          </tr>
          <tr>
            <td class="short">
              <router-link to="/" v-scroll-to="`#aboutus`"
                >初めての方へ</router-link
              >
            </td>
            <td class="short">
              <router-link
                to="/classic-record"
                v-scroll-to="{
                  el: '#app',
                  container: 'body',
                  duration: 750,
                  easing: 'ease',
                  offset: 0,
                }"
                >クラシックレコード</router-link
              >
            </td>
            <td class="short">
              <router-link to="/" v-scroll-to="`#delivery`"
                >宅配買取</router-link
              >
            </td>
            <td class="short">
              <router-link to="/" v-scroll-to="`#qa`">よくある質問</router-link>
            </td>
            <td class="long">
              <router-link to="/privacy">個人情報保護ポリシー</router-link>
            </td>
          </tr>
          <tr>
            <td class="short">
              <router-link to="/" v-scroll-to="`#buyback`"
                >買取情報</router-link
              >
            </td>
            <td class="short">
              <router-link
                to="/jazz-record"
                v-scroll-to="{
                  el: '#app',
                  container: 'body',
                  duration: 750,
                  easing: 'ease',
                  offset: 0,
                }"
                >ジャズレコード</router-link
              >
            </td>
            <td class="short">
              <router-link to="/" v-scroll-to="`#instore`"
                >出張買取</router-link
              >
            </td>
            <td class="short">
              <router-link to="/nagoya"
                >名古屋店</router-link
              >
            </td>
            <!-- <td class="short">
              <a
                href="https://reallygood.jp/pages/%E5%BA%97%E8%88%97%E6%83%85%E5%A0%B1"
                target="_blank"
                rel="noopener noreferrer"
              >
                店舗情報</a
              >
            </td> -->
            <td class="long">
              <router-link to="/ordercontract"
                >特定商取引法に基づく表示</router-link
              >
            </td>
          </tr>
          <tr>
            <td class="short"><a></a></td>
            <td class="short">
              <router-link
                to="/wj-music-record"
                v-scroll-to="{
                  el: '#app',
                  container: 'body',
                  duration: 750,
                  easing: 'ease',
                  offset: 0,
                }"
                >洋楽・邦楽レコード</router-link
              >
            </td>
            <td class="short">
              <router-link to="/" v-scroll-to="`#mobileservice`"
                >店頭買取</router-link
              >
            </td>
            <td class="short">
              <router-link to="/okazaki"
                >岡崎店</router-link
              >
            </td>
            <td class="long">
              <a href="mailto:info@reallygood.jp">お問い合わせ</a>
            </td>
          </tr>
          <tr>
            <td class="short"><a></a></td>
            <td class="short">
              <router-link
                to="/open-reel"
                v-scroll-to="{
                  el: '#app',
                  container: 'body',
                  duration: 750,
                  easing: 'ease',
                  offset: 0,
                }"
                >オープンリール</router-link
              >
            </td>
            <td class="short"></td>
            <td class="short"><a></a></td>
            <td class="long">
              <a></a>
            </td>
          </tr>
        </table>
      </div>

      <img :src="`${rootUrl}/img/logo1.png`" alt="reallygood-ロゴ" />
      <div class="copyright wrap1100">
        <p style="line-height:1.5em;">
          古物商許可証 愛知県公安委員会<br class="sp-kaigyo" />第541162211500号<br>株式会社ロカンタン
        </p>
        <p>
          Copyright © <br class="sp-kaigyo" />Really Good All Rights Reserved.
        </p>
      </div>
    </div>
  </div>
</template>

<script>
import { mapGetters } from "vuex";
export default {
  name: "LayoutFooter",
  components: {},
  props: {
    path: String,
  },
  data() {
    return {};
  },
  computed: {
    ...mapGetters(["rootUrl"]),
  },
};
</script>

<style lang="scss" scoped>
.footer {
  background-color: var(--yellow2);
  padding-top: 100px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;

  img {
    width: 250px;
    margin-bottom: 50px;
  }

  .copyright {
    font-size: 16px;
    color: black;
    text-align: center;
    p {
      padding: 15px 0;
    }
    @media screen and (max-width: 767px) {
      margin-right: 0;
    }
  }
  .footer-menu {
    table {
      width: 100%;
      margin-bottom: 50px;
      tr {
        td {
          text-align: center;
          font-family: "Kiwi Maru", serif;
          font-size: 16px;
          // width: 25%;
        }
      }
    }
    a {
      text-decoration: none;
      color: black;
    }
    @media screen and (max-width: 768px) {
      display: none;
    }
  }
}

.footer-sp-bottom {
  @media screen and (min-width: 541px) and (max-width: 820px) {
    padding-bottom: 100px;
  }
  @media screen and (max-width: 540px) {
    margin-bottom: 50px;
  }
}
.long {
  width: 300px;
}
</style>
