<template>
  <div class="home-customers-voice">
    <div class="title-cover">
      <img :src="`${rootUrl}/img/title04.png`" alt="お客様の声" />
    </div>
    <p class="center space-50" style="font-weight: bold">
      ご利用いただいたお客様の声をご紹介します。高価買取実績が多数あります。
    </p>
    <div class="box-voice-cover">
      <div class="box-voice">
        <UiBoxVoice
          :title="`丁寧な対応でした`"
          :subTitle="`オールジャンルレコード約500点、125,000円で買取させて頂きました`"
          :detail="`聞かなくなったレコードの処分に困り相談したところ、直接見に来てくれました。現在の価値やレコードの状況なども事細かく説明して頂き、その場でレコードの事前見積もりを提示して下さいました。想定の範囲の金額でしたので 引き取ってもらいました。後日最終的な買取額をご連絡いただきましたが見積もってもらった金額より高い結果で良かったです。`"
          :target="`岐阜県 / 60代 / 男性`"
          class="box"
        />
        <UiBoxVoice
          :title="`お店のレコード愛を感じました`"
          :subTitle="`ジャズのレコードを約100枚、82,000円で買取させて頂きました`"
          :detail="`レコードを売ってみたくなりネットでさがし、リアリーグッ ドという店がみつかり、電話をすると郵送でOKと返事をもらった。宅配買取で送ると、あまり時間もかからず返事が来た。この素早い対応にお店のレコード愛を感じました。`"
          :target="`岐阜県 / 60代 / 男性`"
          class="box"
        />
      </div>
      <div class="box-voice">
        <UiBoxVoice
          :title="`使える分からず、処分も考えましたが`"
          :subTitle="`オープンリールデッキ、付属品など5点、40,000円で買取させて頂きました`"
          :detail="`長いこと使用していなかったオープンデッキがあり、故障している可能性が高かったため処分に悩んでいました。ネットで調べたところ、リアリーグッドさんの買取リストに載っていた機種だったため電話で相談してみました。近くだったこともあり、直ぐに出張買取に来て下さいました。動作テストをして頂き、一部動作不良があり減額がありましたが、十分な金額を提示して頂き、安心、納得の買取でした。`"
          :target="`愛知県/ 70代 / 男性`"
          class="box"
        />
        <UiBoxVoice
          :title="`まとめて買取ってもらいました`"
          :subTitle="`オープンリールテープからその他35点、22,000円で買取させて頂きました`"
          :detail="`オープンリールで使用するものか分からないものもありましたが、先に写真を撮りメールを送ったところ、丁寧に説明して頂き、すべて値段もつくということだったので送りました。カセットテープなど一部違うものも含まれていたようでしたが、すべて買取ってもらうことが出来て良かったです。`"
          :target="`長崎県/ 50代 / 女性`"
          class="box"
        />
      </div>
      <div class="box-voice">
        <UiBoxVoice
          :title="`引っ越しのため困っていましたが、<br class='tb-kaigyo'>簡単でした`"
          :subTitle="`クラシックのレコードを約800点、155,000円で買取させて頂きました`"
          :detail="`引っ越しするため、クラシック好きの父親が集めていたレコードのコレクションを処分することにしました。インターネットで調べて、色んなお店に電話しましたが、クラシックの買取はしていないと言われてしまいました。<br>何とかならないものかと相談したところ、他のお店からリアリーグッドを紹介してもらいました。電話したら、すぐ買取に来てくれて丁寧に扱ってくれた上に、買取もしっかりやってくれました。父も喜んでいます。ありがとうございました。`"
          :target="`三重県 / 30代 / 男性`"
          class="box"
        />
        <UiBoxVoice
          :title="`とても丁寧に<br>説明してくださいました`"
          :subTitle="`クラシックのレコードを約150枚、25,000円で買取させて頂きました`"
          :detail="`家の物置から昔祖父が聞いていたクラシックのレコードが段ボールに入っているのを見つけ、プレーヤーもなく聞くことが出来ないので買い取ってもらうことにしました。レコードの知識がなく売れるものかどうかも分からなかったので、電話で相談したらとても丁寧に説明してくださいました。すぐに片づけてしまいたかったので宅配でお願いしましたが、送ってからすぐに査定して連絡をくれて、振り込みしてもらうまでとってもスピーディーでした。`"
          :target="`愛知県 / 40代 / 女性`"
          class="box"
        />
      </div>
      <div class="box-voice">
        <UiBoxVoice
          :title="`一枚一枚丁寧に<br>見てくれたことに感動`"
          :subTitle="`クラシックのレコードを約40枚、2,800円で買取させて頂きました`"
          :detail="`聞かなくなったレコードを処分しようと思い近くの店へ持って行ったところ、まとめてではなく一枚一枚丁寧に見てくれたことに感動を覚えこうして文字にしています。CDも良いとのことなので、また聞かなくなったら持っていきます。`"
          :target="`愛知県/ 60代 / 男性`"
          class="box"
        />
        <UiBoxVoice
          :title="`専門的な知識がある`"
          :subTitle="`クラシックのレコードを約350枚、122,500円で買取させて頂きました`"
          :detail="`レコードのコレクションを整理しているが、査定スタッフはクラシックの知識があり、専門店と謳うだけあり電話で細かい相談が出来た。回答も明瞭で安心感があり、定期的に利用したいと思う。`"
          :target="`大阪府 / 70代 / 男性`"
          class="box"
        />
      </div>
      <div class="box-voice">
        <UiBoxVoice
          :title="`ジャズ以外にも色々ありましたが、<br class='pc-kaigyo'>まとめて買い取ってくれました。`"
          :subTitle="`ジャズレコードを含む300枚を96,000円で買取させて頂きました`"
          :detail="`家にあったジャズのレコード100枚くらいと、その他邦楽や洋楽のレコードをまとめて買い取ってもらいました。<br>引っ越しが近く、急いでいると伝えたら翌日には引き取りに来てくれました。棚にしまったままだったのですが、全てお店の方がやってくれたので助かりました。`"
          :target="`愛知県 / 50代 / 女性`"
          class="box"
        />

        <UiBoxVoice
          :title="`細かく専門的な査定で、<br class='tb-kaigyo-only'>安心してお願いできました。`"
          :subTitle="`ジャズのレコード230枚を158,000円で買取させて頂きました`"
          :detail="`大事に集めてきたレコードでオリジナル盤など貴重なものもあったので、手放すのを迷っていましたが、出張引き取りの際にその場でお見積りをしていただけました。どのレコードが何故価値があるかも丁寧に説明していただき、ジャズに精通していることが良くわかりました。レコードの価値の分かる専門店なので安心してお願いできました。`"
          :target="`岐阜県 / 70代 / 男性`"
          class="box"
        />
      </div>
      <div class="box-voice">
        <UiBoxVoice
          :title="`電話で丁寧に説明してくれたので<br class='tb-kaigyo'>良かったです。`"
          :subTitle="`ジャズのレコード80枚を50,000円で買取させて頂きました`"
          :detail="`父の遺品で、レコードのことは何もわからないのでお電話で相談しました。タイトルやレコードの番号などを伝えたら、おおよその値段を見積もってくれました。買取してもらうのが初めてで、私は少し不安だったのですが、丁寧に説明してくれたのでよかったです。`"
          :target="`滋賀県 / 30代 / 女性`"
          class="box"
        />
        <UiBoxVoice
          :title="`その場ですぐお見積もり<br>してくれました`"
          :subTitle="`ジャズのレコード20枚を7,800円で買取させて頂きました`"
          :detail="`ジャズの国内盤中心に20枚くらいを店舗に持ち込んで買取してもらいました。ジャケットも綺麗で帯などもついていたので思ったより値段を付けてもらえました。その場ですぐお見積もりしてくれて、その日のうちに支払いまでしてくださったので助かりました。`"
          :target="`愛知県 / 50代 / 男性`"
          class="box"
        />
      </div>
      <div class="box-voice">
        <UiBoxVoice
          :title="`オープンリールの専門店は<br class='tb-kaigyo-only'>少ないので相談しやすかった`"
          :subTitle="`ミュージックテープ20点、空リールを５点を28,000円で買取させて頂きました`"
          :detail="`ネットでオープンリールテープの買取をしている店舗を探したら、リアリーグッドさんのホームページを見つけ、専門店とのことだったので依頼しました。<br>近くのリサイクルショップや音楽店では買取してもらえなかったので、値段もしっかりつけていただけて良かったです。`"
          :target="`愛知県 / 50代 / 男性`"
          class="box"
        />

        <UiBoxVoice
          :title="`レコードの出張引取の時に<br class='tb-kaigyo-only'>一緒に買取ってくれました`"
          :subTitle="`ミュージックテープ20点、空リールを５点を28,000円で買取させて頂きました`"
          :detail="`祖父の遺品のレコードの出張買取に来てもらった時に、どういうものかわからず処分に困っていると伝えたら丁寧に説明してくださって、一緒に引き取ってもらうことにしました。<br>思ったより値段がついたので驚きました。`"
          :target="`三重県 / 30代 / 女性`"
          class="box"
        />
      </div>
    </div>
  </div>
</template>

<script>
import { mapGetters } from "vuex";
import UiBoxVoice from "@/components/Ui/BoxVoice.vue";
export default {
  name: "home-if-you-buy",
  components: {
    UiBoxVoice,
  },
  props: {},
  data() {
    return {};
  },
  computed: {
    ...mapGetters(["rootUrl"]),
  },
};
</script>

<style lang="scss" scoped>
.home-customers-voice {
  .title-cover {
    img {
      margin-top: 50px;
      @media screen and (max-width: 768px) {
        margin-top: 50px;
      }
    }
  }

  .box-voice {
    display: flex;
    justify-content: space-between;
    align-items: flex-start;
    @media screen and (max-width: 1024px) {
      flex-direction: column;
    }

    .box {
      width: 49%;
      @media screen and (min-width: 581px) and (max-width: 1024px) {
        width: 70%;
        margin-left: auto;
        margin-right: auto;
      }
      @media screen and (max-width: 580px) {
        width: 100%;
      }
    }
  }
}
</style>