<template>
  <div class="classic-record-article">
  
    <div
      class="back"
      :style="`background-image: url(${rootUrl}/img/reallygood-pc-top-kobetsu01.jpg);`"
    >
    </div>
    <div class="article-title">
      <p>{{ articleData.title }}<br />オープンリールの高価買取</p>
    </div>
    <div class="article-text wrap960 space-100">
      {{ articleData.text }}
    </div>

    <div class="space-150 wrap960">
      <div class="record-box">
         <ArticleItemBox
          v-for="productId in articleData.productIdList"
          :key="productId"
          :categoryId="articleData.categoryId"
          :productId="productId"
          class="item-box"
        />
      </div>
    </div>

    <div
      class="fixed-back space-150"
      :style="`background-image: url(${rootUrl}/img/back-img02.jpg);`"
    >
      <OpenReelReason />
    </div>

    <div class="space-100 wrap960">
      <ArticlePromise />
    </div>
    <div class="wrap960 title-cover">
      <router-link to="/">
      <img
        alt="リアリーグッドの詳細を見てみる"
        :src="`${rootUrl}/img/title17.png`"
      />
      </router-link>
      </div>


  </div>
</template>

<script>
import { mapGetters } from "vuex";
import ArticleItemBox from "@/components/Article/ItemBox.vue";
import OpenReelReason from "@/components/OpenReel/Reason.vue";
import ArticlePromise from "@/components/Article/Promise.vue";

export default {
  name: "Home",
  components: {
    ArticleItemBox,
    OpenReelReason,
    ArticlePromise,
  },
  props: {
    articleId:String,
  },
  data() {
    return {
      articleData: {
        id: "",
        categoryId: "",
        title: "",
        text: "",
       productIdList: [],
      },
    };
  },
  computed: {
    ...mapGetters(["rootUrl"]),
  },
  watch: {},
  created() {
    this.$axios
      .get(`${this.rootUrl}/api/article/get-article-data?article_id=${this.articleId}`)
      .then((response) => {
        console.log(response);
        // alert(response.data.status);
        if (response.data.status === 200) {
          // console.log('ok')
          this.articleData = response.data.articleData;
          // alert(response.data.articleData);
          return;
        } else if (
          response.data.status === 401 ||
          response.data.status === 400
        ) {
          alert(response.data.message);
          return;
        } else {
          alert("エラーが発生しました。");
          return;
        }
      })
      .catch((err) => {
        console.log(err);
      });
  },
  methods: {},
};
</script>
<style lang="scss" scoped>
.classic-record-article {
  .top {
    img {
      width: 100%;
    }
    position: relative;
  }
  .article-title {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    -webkit-transform: translate(-50%, -50%);
    -ms-transform: translate(-50%, -50%);
    z-index: 3;
    @media screen and (max-width: 820px) {
      top: 35%;
      width: 90%;
    }
    p {
      color: white;
      font-size: 31px;
      font-weight: bold;
      text-align: center;
      line-height: 1.6em;
    }
  }

}
.back {
  padding-top: 10px;
  padding-bottom: 150px;
  background-size: cover;
  background-repeat: no-repeat;
}
.design {
  width: 100vw !important;
  vertical-align: top;
}
.spcae1 {
  padding-bottom: 250px;
}

.record-box {
  display: flex;
  justify-content: space-between;
  flex-wrap: wrap;
  @media screen and (max-width: 768px) {
  }
  // justify-content: space-between;
  .item-box {
    width: 24%;
    margin-right: 1%;
    @media screen and (max-width: 768px) {
      width: 48%;
      margin-right: 0;
    }
  }
}
.record-box::before {
  content: "";
  display: block;
  width: 24%;
  order: 1;
  @media screen and (max-width: 768px) {
    width: 48%;
  }
}
.record-box::after {
  content: "";
  display: block;
  width: 24%;
  @media screen and (max-width: 767px) {
    display: none;
  }
}


// .record-box {
//   display: flex;
//   flex-wrap: wrap;
//   @media screen and (max-width: 768px){
//     flex-direction: column;
//   }
//   // justify-content: space-between;
//   .item-box {
//     width: 32%;
//     margin-right: 1%;
//       @media screen and (max-width: 768px){
//   width: 100%;
//   margin-right: 0;
//   }
//   }
// }
.title-cover{
  text-align: center;
  margin-bottom: 200px;

  a:hover{
    opacity: 0.6;
  }
}
  .back {
    background-size: cover;
    background-position: right 10% bottom 5%;
    background-repeat: no-repeat;
    height: 640px;
    display: flex;
    align-items: center;
    justify-content: center;
    position: relative;
    @media screen and (max-width: 768px) {
      background-position: right 50% bottom 5%;
      height: 400px;
    }
  }
.fixed-back {
  position: relative;
  // min-height: 100%;
  // height: 1350px; /*--スクロールをさせるため 高さ指定--*/
  background-size: cover; /*--背景画像のサイズ--*/
  // background-attachment: fixed; /*--背景画像の固定--*/
  background-repeat: no-repeat; /*--背景画像の繰り返し--*/
  background-position: center center; /*--背景画像の位置--*/
  z-index: 2;
  padding: 5em 0;

  @media screen and (max-width: 1024px) {
    background-attachment: initial;
    width: 100%;
    // background-size: auto 100%;
    // background-size:contain;
  }
}
</style>
