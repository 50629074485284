<template>
  <div class="okazaki">
    <ShopContents :shopId="ShopConsts.ID_OKAZAKI" />
  </div>
</template>

<script>
import { mapGetters } from "vuex";
import ShopConsts from "@/consts/ShopConsts"
import ShopContents from "@/components/Shop/ShopContents.vue";

export default {
  name: "okazaki",
  components: {
    ShopContents
  },
  props: {},
  data() {
    return {
      ShopConsts: ShopConsts
    };
  },
  computed: {
    ...mapGetters(["rootUrl"]),
  },
  watch: {},
  methods: {},
};
</script>

<style lang="scss" scoped></style>
