<template>
  <div class="shop-shop-data">
    <div class="access-cover">
      <div class="map">
        <iframe
          :src="shopData.googleMapSrc"
          width="100%" height="484px" style="border:0;" allowfullscreen="" loading="lazy"
          referrerpolicy="no-referrer-when-downgrade"></iframe>
      </div>
      <table class="access-outer">
        <tr>
          <th>店舗名</th>
          <td>Really Good {{ shopData.name }}<br /><span style="margin-left: -10px;">（リアリーグッド {{ shopData.name
              }}）</span>
          </td>
        </tr>
        <tr>
          <th>TEL</th>
          <td>
            <table class="access-inner">
              <tr>
                <th>店舗：</th>
                <td>{{ shopData.phoneNumber }}</td>
              </tr>
              <tr>
                <th>買取専用：</th>
                <td>{{ shopData.buyingPhoneNumber }}</td>
              </tr>
            </table>
          </td>
        </tr>
        <tr>
          <th>住所</th>
          <td v-html="shopData.address"></td>
        </tr>
        <tr>
          <th>営業時間</th>
          <td v-html="shopData.businessHoursText"></td>
        </tr>
      </table>
    </div>
    <div class="image-list">
      <div class="about-us-image" v-for="(imageData, index) in shopData.imageList" :key="index">
        <img :src="`${rootUrl}${imageData.path}`" :alt="imageData.name" />
      </div>
    </div>
  </div>
</template>

<script>
import { mapGetters } from "vuex";
import ShopConsts from "@/consts/ShopConsts";

export default {
  name: "shop-data",
  components: {
    // 
  },
  props: {
    shopId: String
  },
  data() {
    return {
      // Consts
      ShopConsts: ShopConsts,
    };
  },
  computed: {
    ...mapGetters(["rootUrl", "shopList"]),
    shopData() {
      // 
      let shopData = this.shopList.find(shopData => shopData.id === this.shopId);

      // 
      let addingShopData = null;
      if (this.$route.path === '/') { // TOPの場合
        switch (this.shopId) {
          // 
          case ShopConsts.ID_NAGOYA:
            addingShopData = {
              imageList: [
                { path: "/img/about-us-image04.jpg", name: "店内写真1" },
                { path: "/img/about-us-image01.jpg", name: "店内写真2" },
                { path: "/img/about-us-image06.jpg", name: "店内写真3" },
              ]
            };
            break;

          // 
          case ShopConsts.ID_OKAZAKI:
            addingShopData = {
              imageList: [
                { path: "/img/about-us-image07.jpg", name: "店内写真1" },
                { path: "/img/about-us-image08.jpg", name: "店内写真2" },
                { path: "/img/about-us-image09.jpg", name: "店内写真3" },
              ]
            };
            break;

          default:
            // 
            break;
        }
      } else {
        switch (this.shopId) {
          // 
          case ShopConsts.ID_NAGOYA:
            addingShopData = {
              imageList: [
                { path: "/img/nagoya01.jpg", name: "店内写真1" },
                { path: "/img/nagoya02.jpg", name: "店内写真2" },
                { path: "/img/nagoya03.jpg", name: "店内写真3" },
              ]
            };
            break;

          // 
          case ShopConsts.ID_OKAZAKI:
            addingShopData = {
              imageList: [
                { path: "/img/okazaki01.jpg", name: "店内写真1" },
                { path: "/img/okazaki02.jpg", name: "店内写真2" },
                { path: "/img/okazaki03.jpg", name: "店内写真3" },
              ]
            };
            break;

          default:
            // 
            break;
        }
      }

      // 
      return { ...shopData, ...addingShopData };
    },
  },
  methods: {},
};
</script>

<style lang="scss" scoped>
.access-cover {
  display: flex;
  justify-content: space-between;

  @media screen and (min-width: 581px) and (max-width: 768px) {
    padding-bottom: 10px;
  }

  @media screen and (max-width: 768px) {
    justify-content: center;
    align-items: center;
    flex-direction: column;
    padding-bottom: 10px;
  }

  .map {
    width: 55%;

    @media screen and (min-width: 581px) and (max-width: 768px) {
      width: 70%;
    }

    @media screen and (max-width: 580px) {
      width: 100%;
    }
  }

  .access-outer {
    width: 40%;
    font-size: 18px;

    @media screen and (min-width: 581px) and (max-width: 768px) {
      margin-top: 20px;
      width: 70%;
    }

    @media screen and (max-width: 580px) {
      width: 100%;
      margin-top: 20px;
    }

    tr {

      th,
      td {
        border: 1px solid black;
        vertical-align: middle;
      }

      th {
        padding: 8px 5px;
        width: 100px;
        // font-weight: 700;
      }

      td {
        padding: 20px 0 20px 30px;
        line-height: 1.3em;

        @media screen and (max-width: 768px) {
          padding: 10px;
        }

        .access-inner {
          tr {

            th,
            td {
              border: none;
              padding: 10px 0;
              font-weight: 400;

              @media screen and (max-width: 768px) {
                display: block;
                padding: 0;
              }
            }

            th {
              text-align: justify;
              text-align-last: justify;
              text-justify: inter-ideograph;
              width: 100px;
              text-align: left;
            }

            td {
              padding-left: 0;
            }
          }
        }
      }
    }
  }
}

.image-list {
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
  margin: 50px 0;

  @media screen and (max-width: 768px) {
    margin: 20px 0;
  }

  .about-us-image {
    width: 32%;
  }
}
</style>
