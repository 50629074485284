<template>
  <div class="jazzrecord-reason">

    <div class="wrap1100">
    <div class="title-cover wrap960">
      <img :src="`${rootUrl}/img/title18.png`" alt="買取についてよくある質問" />
    </div>

      <UiBoxReason
        :imgpath="`reason-01.jpg`"
        :name="`レコードイメージ 
      `"
        :title="`レコード専門店だから<br>実現できる圧倒的な買取力`"
        :des="`レコード専門店だからこそ、レコードに詳しいスタッフが適切な査定を行うことができます。<br>リサイクルシ ョップではできない専門的な査定ができるため、他にはない高価買取を実現しています。<br>段ボール１箱でいくらなどざっくり査定をせず、１つ１つを丁寧に査定。<br>必要であれば再生機器でのチェックを行うなど、状態を細部まで把握して査定金額を算出します。<br>付属品も買取表を元に価値のあるものを見逃しません。`"
      />
      <UiBoxReason
        :imgpath="`reason-02.jpg`"
        :name="`店内イメージ 
      `"
        :title="`<span>買取率99%！ジャズレコードなら何でも買い取れる</span>`"
        :des="`リアリーグッドは、ジャズレコードに特化したレコード店です。そのため、ジャズレコードについて買い取れないものはほぼありません。買取率は99%の実績です。迷ったらリアリーグッドへどうぞ。`"
        class="small"
      />
      <UiBoxReason
        :imgpath="`reason-03.jpg`"
        :name="`買取体制イメージ 
      `"
        :title="`お客様が利用しやすい<br>買取体制`"
        :des="`宅配買取の送料、出張買取の出張料金、査定料、お支払い時の振込手数料、返送料・引取料などすべて無料で、お客様の負担は一切ございません。ご希望のお客様には梱包キットのご用意もしております。お客様が損をする、失敗することがないため、お気軽に安心してご利用いただけます。`"
      />
      <UiBoxReason
        :imgpath="`reason-04.jpg`"
        :name="`査定イメージ 
      `"
        :title="`スピーディーな査定！<br>１営業日以内に対応`"
        :des="`お客様をお待たせしないよう、１営業日以内でのお見積もり回答を行っています。<br>お急ぎのお客様の査定も迅速かつ丁寧に査定させていただきます。<br>スケジュールが合えば当日出張も可能です。引っ越しや遺品整理など早急なご依頼にも対応いたします。`"
      />
      <UiBoxReason
        :imgpath="`reason-05.jpg`"
        :name="`お客様へイメージ 
      `"
        :title="`しっかり次のお客さまへ<br>繋げます`"
        :des="`リアリーグッドではお客さまより買取させて頂いたお品物を、そのまま販売することはございません。レコードは専用のクリーニング機械で一枚一枚丁寧に掃除をし、新品の袋に変えて、大切に販売します。テープは1本1本録音データに欠損や不良がないか、再生チェックをした後に販売します。オーディオ機器は入念なチェックをして、正常、不良箇所を把握した上で、必要なら修理・メンテナンスを施して販売します。レコード を愛しているお客様との取引が多数ある専門店だからこそ、次のお客さまにしっかりと繋げることができます。`"
      />
    </div>
  </div>
</template>

<script>
import { mapGetters } from "vuex";
import UiBoxReason from "@/components/Ui/BoxReason";
export default {
  name: "jazzrecord-reason",
  components: {
    UiBoxReason,
  },
  props: {},
  data() {
    return {};
  },
  computed: {
    ...mapGetters(["rootUrl"]),
  },
};
</script>

<style lang="scss" scoped>
// .jazzrecord-reason {
//   height: 1000px;
//   overflow: scroll;
//   z-index: 3;
//   //  background-attachment: fixed;
//   -ms-overflow-style: none; /* IE, Edge 対応 */
//   scrollbar-width: none; /* Firefox 対応 */
// }

// .jazzrecord-reason::-webkit-scrollbar {
//   /* Chrome, Safari 対応 */
//   display: none;
// }
.title-cover{
  width: 100%;
}
</style>