<template>
  <div class="privacy wrap1100">
    <div>
      <h1>個人情報保護ポリシー</h1>
      <div class="privacypages pace-100">
        <p>
          「リアリーグッド」では利用者の皆様が安心してご利用頂けるよう最低限の個人情報を提供頂いております。<br />
          「リアリーグッド」ではご提供頂いた個人情報の保護について最大限の注意を払っています。<br />
          「リアリーグッド」の個人情報保護についての考え方は以下の通りです。<br />
        </p>

        <ul>
          <li>
            弊社では会員様により登録された個人及び団体や法人の情報については、
            「リアリーグッド」
            において最先端の機能やサービスを開発・提供するためにのみ利用し、
            会員個人情報の保護に細心の注意を払うものとします。
          </li>
          <li>
            このプライバシーポリシーの適用範囲は、「リアリーグッド」
            で提供されるサービスのみであります。 (範囲は下記、第1項に規定)
          </li>
          <li>
            本規約に明記された場合を除き、目的以外の利用は致しません。(目的は下記、第2項に規定)
          </li>
          <li>
            本規約に明記された場合を除き、第三者への開示は致しません。(管理は下記、第2項に規定)
          </li>
          <li>
            その他本規約に規定された方法での適切な管理を定期的に行います。
          </li>
          <li>
            「リアリーグッド」は利用者の許可なくして、プライバシーポリシーの変更をすることができます
            「リアリーグッド」が、個人情報取得内容の変更・利用方法の変更・開示内容の変更等をした際には、
            利用者がその内容を知ることができるよう、弊社ホームページのお知らせに公開し、
            このプライバシーポリシーに反映することにより通知致します。
            1．「リアリーグッド」のプライバシーポリシーについての考え方が適用される範囲
          </li>
          <li>
            「リアリーグッド」のプライバシーポリシーについての考え方は、
            会員が「リアリーグッド」のサービスを利用される場合に適用されます。
          </li>
          <li>
            会員が「リアリーグッド」のサービスを利用される際に収集される個人情報は、
            「リアリーグッド」の個人情報保護についての考え方に従って管理されます。
          </li>
          <li>
            「リアリーグッド」の個人情報保護考え方は、
            「リアリーグッド」が直接提供される
            サービスのみであり、リンク等でつながった他の組織・会社等のサービスは適用範囲外となります。
          </li>
          <li>
            「リアリーグッド」のサービスのご利用は、利用者の責任において行われるものとします。
          </li>
          <li>
            弊社のホームページ及び当ホームページにリンクが設定されている他のホームページから
            取得された各種情報の利用によって生じたあらゆる損害に関して、「リアリーグッド」は
            一切の責任を負いません。 2．「リアリーグッド」の個人情報の収集と利用
            「リアリーグッド」では会員の皆様に最先端の機能やサービスを開発・提供するために、
            会員について幾つかの個人情報が必要となります。
          </li>
          <li>
            ショップのID・パスワードは利用者ご自身の責任において管理をお願い致します。
            -
            パスワードは定期的に変更し、他人が類推しやすいような名前や生年月日、
            電話番号など は避けることをお勧め致します。 -
            また、知人・友人などであっても開示・貸与・譲渡しないで下さい。 -
            お問合せのメールや弊社のホームページ上の
            Q&amp;Aにはパスワードを書き込まないようお願い致します。
          </li>
          <li>
            収集された個人情報は「リアリーグッド」のサービスを提供するために必要な限度においてのみ利用し、
            次の場合を除き、いかなる第三者にも提供致しません。
          </li>
          <li>
            会員の同意がある場合 -
            会員から個人情報の利用に関する同意を求めるための電子メールを送付する場合
            -
            あらかじめ弊社と機密保持契約を締結している企業（例えば、業務委託先）
            等に必要な限度において開示する場合 -
            会員に対し、弊社、または、弊社の業務提携先等の広告宣伝のための電子メール、
            ダイレクトメールを送付する場合 -
            「リアリーグッド」における会員の行為が、「リアリーグッド」方針・告知、
            「リアリーグッド」の利用規約等に違反している場合に、他の会員、第三者または弊社の権利、財産を保護するために必要と認められる場合
            -
            裁判所、行政機関の命令等、その他法律の定めに従って個人情報の開示を求められた場合、
            または犯罪の捜査、第三者に対する権利侵害の排除若しくはその予防、
            その他これに準ずる必要性ある場合
          </li>
          <li>
            会員は、弊社に対し、個人情報を上に定める方法で利用することにつきあらかじめ同意するものとし、
            異議を述べないものとします。
          </li>
          <li>
            また、「リアリーグッド」では次の様な場合、弊社のビジネスパートナーと会員の個人情報を
            共有する事があります。 -
            会員向け特別サービスなど、事業的な理由がある場合。
            この場合、情報を提供する前に会員の同意を求め、同意無しでは提供致しません。
            - 統計資料作成、市場調査、データ分析などを行う場合。
            この場合、特定個人を判別することができない様に加工された情報だけを提供致します。
            3. 問い合わせ先
            ここに示した個人情報についての考え方についてご不明な点などございましたら
            お問合せフォームからお問い合わせください。
          </li>
        </ul>
            <div class="button-cover">
        <el-button @click="top" type="info" class="back">トップページに戻る</el-button>
      </div>
      </div>
    </div>
  </div>
</template>

<script>
import { mapGetters } from "vuex";

export default {
  name: "Privacy",
  components: {},
  props: {},
  data() {
    return {};
  },
  computed: {
    ...mapGetters(["rootUrl"]),
  },
  watch: {},
  methods: {
        top() {
      this.$router.push("/");
    },
  },
};
</script>
<style lang="scss" scoped>
h1 {
  margin-top: 100px;
  margin-bottom: 50px;
  @media screen and (max-width: 768px) {
    margin-top: 50px;
  }
}
.privacypages {
  margin-bottom: 100px;
  font-size: 16px;
  line-height: 1.5em;
  p{
margin-bottom: 30px;
  }
  ul{
    li{
      list-style-type: disc;
      margin-left: 20px;
      padding-left: 5px;
      margin-bottom: 10px;
    }
  }
  .button-cover{
    margin-top: 50px;
  }
}
</style>
