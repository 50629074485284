<template>
  <div class="home-about-us">
    <div class="back" :style="`background-image: url(${rootUrl}/img/back-img01.jpg);`">
      <div class="title-cover wrap960">
        <img :src="`${rootUrl}/img/title06.png`" alt="わたしたちリアリーグッドとは" />
      </div>
    </div>
    <p class="space-100 wrap960">
      レコードやCDなどの音楽ソフトを中心に、オーディオ機器や関連パーツまで、音楽に関連する品の買取・販売を行っております。
      音楽専門店として、豊富な商品知識と顧客ネットワークを活かした販売力が強みです。<br />
      買取は全国を対象に宅配、出張、店頭で受け付けております。貴重なお品はいつでもご相談、どこにでも査定に伺います。実店舗も営業しております。お持ち込みも大歓迎です。<br />
      お売り頂いた大切なお品は、店舗とインターネット通販を通して次のオーナーにしっかり繋げられるよう、専門店として責任ある販売を心掛けております。
    </p>

    <div class="wrap1100 space-50" id="shop-info">
      <div v-if="displayShopData === true">
        <ShopData :shopId="ShopConsts.ID_NAGOYA" />
        <ShopData :shopId="ShopConsts.ID_OKAZAKI" />
      </div>

      <div class="sub-title">
        <p>
          レコード・オーディオ好きが高じて始めた専門店。<br />日本でも有数なこだわりあるサービスを行っています。
        </p>
      </div>

      <UiBoxService :imgpath="`service01.jpg`" :name="`クリーニングイメージ 
      `" :title="`超音波機器による<br>本格的なクリーニング`"
        :des="`一般的な店舗では汚れをクロスで拭き上げるなど手作業での清掃ですが、リアリーグッドでは超音波洗浄機など本格的な機器を使用したクリーニングを行っています。 <br>当店で買い取った商品はすべてこのマシーンでクリーニングし、キレイな状態で店頭に並びます。`" />
      <UiBoxService :imgpath="`service02.jpg`" :name="`クリーニングイメージ 
      `" :title="`レコードの反り直し<br>サービスも行っています`"
        :des="`リアリーグッドには、レコードの反り直しができる機器まであります。この機器を持っているショップは日本でほとんどないのではないでしょうか。レコードの反り直しについては、うまくできる場合とそうでない場合があるため、リアリーグッドでは、お客様に無料サービスとして提供しています。`" />
      <UiBoxService :imgpath="`service03.jpg`" :name="`クリーニングイメージ 
      `" :title="`オープンリールテープの<br>再生チェック`"
        :des="`買い取ったオープンリールテープは、全て再生チェックをし、テープの欠損や劣化がないかを確認しています。再生不良のあるものは販売しておりません。<br>オークションなどで売られているミュージックテープは録音内容の未確認な物が多いため、当店では安心してご購入いただくことができます。`" />
    </div>
  </div>
</template>

<script>
import { mapGetters } from "vuex";
import UiBoxService from "@/components/Ui/BoxService";
import ShopData from "@/components/Shop/ShopData.vue";
import ShopConsts from "@/consts/ShopConsts";

export default {
  name: "home-about-us",
  components: {
    UiBoxService,
    ShopData,
  },
  props: {
    displayShopData: Boolean
  },
  data() {
    return {
      // Consts
      ShopConsts: ShopConsts,
    };
  },
  computed: {
    ...mapGetters(["rootUrl"]),
  },
};
</script>

<style lang="scss" scoped>
.home-about-us {
  .back {
    background-size: cover;
    background-position: right 10% bottom 5%;
    background-repeat: no-repeat;
    height: 640px;
    display: flex;
    align-items: center;
    justify-content: center;

    // background-size: auto 80%;
    @media screen and (max-width: 768px) {
      background-position: right 50% bottom 5%;
    }
  }

  .sub-title {
    border-left: 10px solid black;
    padding-left: 20px;
    font-size: 36px;
    font-weight: bold;
    margin-top: 150px;

    @media screen and (max-width: 768px) {
      font-size: 19px;
      margin-top: 50px;
    }

    @media screen and (min-width: 581px) and (max-width: 768px) {
      margin-left: 4%;
    }

    @media screen and (max-width: 580px) {
      margin-left: 0;
    }
  }
}
</style>