<template>
  <div class="slider-box">
    <div class="slider">
      <swiper
        :options="swiperOption"
        :style="`width:${width}%;height:${height}%;`"
      >
        <swiper-slide v-for="box in boxes" :key="box.id">
          <div class="item-box">
            <div class="square_image">
              <img :src="`${rootUrl}/storage/${box.imgPath}`" :alt="box.name" />
              <!-- <img :src="`${rootUrl}/img/${box.imgpath}`" :alt="box.name" /> -->
            </div>
            <div class="text-cover">
              <div class="title">
                <p>{{ getCategoryName(box.categoryId) }}</p>
              </div>
              <p class="des" v-html="box.text"></p>
              <div class="contract">
                <p class="small">【成約】</p>
                <p>{{ box.price | localeNum}}円で買取</p>
              </div>
            </div>
          </div>
        </swiper-slide>
        <!-- <div class="slide-op" v-show="button"> -->
        <div v-show="button" class="swiper-pagination" slot="pagination"></div>
        <div
          v-show="button"
          class="swiper-button-prev"
          slot="button-prev"
        ></div>
        <div
          v-show="button"
          class="swiper-button-next"
          slot="button-next"
        ></div>

        <!-- </div> -->
      </swiper>
    </div>
  </div>
</template>

<script>
export default {
  name: "slider-box",
  components: {},
  props: {
    boxes: Array,
    swiperOption: Object,
    height: Number,
    width: Number,
    button: Boolean,
  },
  // props: {
  //   path: String,
  //   type: Number,
  // },
  data() {
    return {};
  },
  computed: {
    rootUrl() {
      return this.$store.getters.rootUrl;
    },
  },
  methods: {
    getCategoryName(categoryId) {
      const categoryData = this.$store.getters["categoryData"](categoryId);
      return categoryData.name;
    },
  },
  plugins: [{ src: "~/plugins/swiper", mode: "client" }],
};
</script>

<style lang="scss" scoped>
.swiper-container {
  // width: 100%;
}

.swiper-slide {
  text-align: center;
  font-size: 32px;
  font-weight: 700;
  background-color: var(--black);
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: center;
  -ms-flex-pack: center;
  justify-content: center;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  @media screen and (max-width: 768px) {
    padding-left: 20px;
    padding-right: 20px;
  }
  // border-left:1px solid #fff;
}
.swiper-wrapper {
  transition-timing-function: linear !important;
}
.item-box {
  width: 100%;
  .square_image {
    position: relative;
    // min-height: 150px !important;

    img {
      position: absolute;
      width: 100%;
      height: 100%;
      top: 0;
      right: 0;
      bottom: 0;
      left: 0;
      margin: auto;
      object-fit: cover;
    }
  }
  .square_image::before {
    content: "";
    display: block;
    padding-top: 100%;
  }

  .text-cover {
    background-color: white;
    padding: 30px 10px;
    text-align: left;

    .title {
      font-size: 16px;
      color: white;
      display: flex;
      align-items: center;
      min-height: 55px;
      display: flex;
      align-items: flex-start;
      @media screen and (max-width: 768px) {
        font-size: 15px;
      }
      p {
        min-height: 42px;
        background-color: black;
        padding: 5px 10px;
        display: flex;
        align-items: center;
        @media screen and (max-width: 768px) {
          padding: 0 10px;
        }
      }
    }
    .des {
      margin-top: 20px;
      font-size: 18px;
      line-height: 1.6em;
      min-height: 100px;
    }
    .contract {
      color: #ff0000;
      margin-top: 20px;
      font-size: 24px;
      text-align: center;
      

      .small {
        font-size: 18px;
        margin-bottom: 5px;
        text-align: left;
      }
    }
  }
}
</style>
