<template>
  <div class="ctrl-login">

    <div class="wrap">
    <CtrlProductRegisterProduct :categoryId="categoryId" :productId="productId"/>
    </div>

  </div>
</template>

<script>
import { mapGetters } from "vuex";
import CtrlProductRegisterProduct from "@/components/Ctrl/Product/RegisterProduct.vue";
export default {
  name: 'ctrl-login',
  components: {
    CtrlProductRegisterProduct,
  },
      props: {
      categoryId:String,
      productId:String,
    },
data() {
    return {};
  },
  computed: {
  ...mapGetters(["rootUrl"]),
  },
};
</script>

<style lang="scss" scoped>

</style>
