<template>
  <div class="ui-box-reason">
    <div class="box-cover">
      <img :src="`${rootUrl}/img/${imgpath}`" :alt="name" />
      <div class="box-right">
        <div class="title">
          <p v-html="title"></p>
        </div>
        <p v-html="des" class="des"></p>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "ui-box-reason",
  components: {},
  props: {
    imgpath: String,
    name: String,
    title: String,
    des: String,
  },
  data() {
    return {};
  },
  computed: {
    rootUrl() {
      return this.$store.getters.rootUrl;
    },
  },
  methods: {},
};
</script>

<style lang="scss" scoped>
.ui-box-reason {
  margin-top: 100px;
  background-color: white;
  padding: 70px;
  @media screen and (max-width: 768px) {
    padding: 20px;
    margin: 0 auto;
    margin-top: 100px;
  }
  @media screen and (min-width: 581px) and (max-width: 768px) {
    width: 70%;
  }
  @media screen and (max-width: 580px) {
    width: 100%;
  }
  .box-cover {
    display: flex;
    justify-content: space-between;
    align-items: flex-start;
    @media screen and (max-width: 768px) {
      flex-direction: column;
      align-items: center;
    }

    img {
      width: 48%;
      object-fit: contain;
      @media screen and (min-width: 581px) and (max-width: 768px) {
        width: 70%;
      }
      @media screen and (max-width: 580px) {
        width: 100%;
      }
    }
    .box-right {
      width: 48%;
      @media screen and (min-width: 581px) and (max-width: 768px) {
        width: 70%;
      }
      @media screen and (max-width: 580px) {
        width: 100%;
      }
      .title {
        font-size: 32px;
        font-weight: 700;
        border-bottom: 1px solid black;
        padding-bottom: 8px;
        margin-bottom: 8px;
        line-height: 1.4em;
        color: var(--green);
        @media screen and (max-width: 768px) {
          font-size: 20px;
          margin-top: 10px;
        }
        :deep(span) {
          font-size: 28px;
          @media screen and (max-width: 768px) {
            font-size: 18px;
          }
        }
      }
      .des {
        font-size: 18px;
        line-height: 1.8em;
        letter-spacing: 0.01em;
      }
    }
  }
}
</style>
