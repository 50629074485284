var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"layout-footer"},[_c('div',{staticClass:"footer footer-sp-bottom"},[_c('div',{staticClass:"footer-menu wrap960"},[_c('table',[_c('tr',[_c('td',{staticClass:"short"},[_c('router-link',{attrs:{"to":"/"}},[_vm._v("Top")])],1),_c('td',{staticClass:"short"},[_c('router-link',{directives:[{name:"scroll-to",rawName:"v-scroll-to",value:("#strong"),expression:"`#strong`"}],attrs:{"to":"/"}},[_vm._v("買取強化アイテム")])],1),_c('td',{staticClass:"short"},[_c('router-link',{directives:[{name:"scroll-to",rawName:"v-scroll-to",value:("#purchase"),expression:"`#purchase`"}],attrs:{"to":"/"}},[_vm._v("買取方法")])],1),_c('td',{staticClass:"short"},[_c('router-link',{directives:[{name:"scroll-to",rawName:"v-scroll-to",value:("#voice"),expression:"`#voice`"}],attrs:{"to":"/"}},[_vm._v("お客様の声")])],1),_c('td',{staticClass:"long"},[_c('router-link',{attrs:{"to":"/contact"}},[_vm._v("メールでの買取依頼・お問い合わせ")])],1)]),_c('tr',[_c('td',{staticClass:"short"},[_c('router-link',{directives:[{name:"scroll-to",rawName:"v-scroll-to",value:("#aboutus"),expression:"`#aboutus`"}],attrs:{"to":"/"}},[_vm._v("初めての方へ")])],1),_c('td',{staticClass:"short"},[_c('router-link',{directives:[{name:"scroll-to",rawName:"v-scroll-to",value:({
                el: '#app',
                container: 'body',
                duration: 750,
                easing: 'ease',
                offset: 0,
              }),expression:"{\n                el: '#app',\n                container: 'body',\n                duration: 750,\n                easing: 'ease',\n                offset: 0,\n              }"}],attrs:{"to":"/classic-record"}},[_vm._v("クラシックレコード")])],1),_c('td',{staticClass:"short"},[_c('router-link',{directives:[{name:"scroll-to",rawName:"v-scroll-to",value:("#delivery"),expression:"`#delivery`"}],attrs:{"to":"/"}},[_vm._v("宅配買取")])],1),_c('td',{staticClass:"short"},[_c('router-link',{directives:[{name:"scroll-to",rawName:"v-scroll-to",value:("#qa"),expression:"`#qa`"}],attrs:{"to":"/"}},[_vm._v("よくある質問")])],1),_c('td',{staticClass:"long"},[_c('router-link',{attrs:{"to":"/privacy"}},[_vm._v("個人情報保護ポリシー")])],1)]),_c('tr',[_c('td',{staticClass:"short"},[_c('router-link',{directives:[{name:"scroll-to",rawName:"v-scroll-to",value:("#buyback"),expression:"`#buyback`"}],attrs:{"to":"/"}},[_vm._v("買取情報")])],1),_c('td',{staticClass:"short"},[_c('router-link',{directives:[{name:"scroll-to",rawName:"v-scroll-to",value:({
                el: '#app',
                container: 'body',
                duration: 750,
                easing: 'ease',
                offset: 0,
              }),expression:"{\n                el: '#app',\n                container: 'body',\n                duration: 750,\n                easing: 'ease',\n                offset: 0,\n              }"}],attrs:{"to":"/jazz-record"}},[_vm._v("ジャズレコード")])],1),_c('td',{staticClass:"short"},[_c('router-link',{directives:[{name:"scroll-to",rawName:"v-scroll-to",value:("#instore"),expression:"`#instore`"}],attrs:{"to":"/"}},[_vm._v("出張買取")])],1),_c('td',{staticClass:"short"},[_c('router-link',{attrs:{"to":"/nagoya"}},[_vm._v("名古屋店")])],1),_c('td',{staticClass:"long"},[_c('router-link',{attrs:{"to":"/ordercontract"}},[_vm._v("特定商取引法に基づく表示")])],1)]),_c('tr',[_vm._m(0),_c('td',{staticClass:"short"},[_c('router-link',{directives:[{name:"scroll-to",rawName:"v-scroll-to",value:({
                el: '#app',
                container: 'body',
                duration: 750,
                easing: 'ease',
                offset: 0,
              }),expression:"{\n                el: '#app',\n                container: 'body',\n                duration: 750,\n                easing: 'ease',\n                offset: 0,\n              }"}],attrs:{"to":"/wj-music-record"}},[_vm._v("洋楽・邦楽レコード")])],1),_c('td',{staticClass:"short"},[_c('router-link',{directives:[{name:"scroll-to",rawName:"v-scroll-to",value:("#mobileservice"),expression:"`#mobileservice`"}],attrs:{"to":"/"}},[_vm._v("店頭買取")])],1),_c('td',{staticClass:"short"},[_c('router-link',{attrs:{"to":"/okazaki"}},[_vm._v("岡崎店")])],1),_vm._m(1)]),_c('tr',[_vm._m(2),_c('td',{staticClass:"short"},[_c('router-link',{directives:[{name:"scroll-to",rawName:"v-scroll-to",value:({
                el: '#app',
                container: 'body',
                duration: 750,
                easing: 'ease',
                offset: 0,
              }),expression:"{\n                el: '#app',\n                container: 'body',\n                duration: 750,\n                easing: 'ease',\n                offset: 0,\n              }"}],attrs:{"to":"/open-reel"}},[_vm._v("オープンリール")])],1),_c('td',{staticClass:"short"}),_vm._m(3),_vm._m(4)])])]),_c('img',{attrs:{"src":(_vm.rootUrl + "/img/logo1.png"),"alt":"reallygood-ロゴ"}}),_vm._m(5)])])}
var staticRenderFns = [function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('td',{staticClass:"short"},[_c('a')])},function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('td',{staticClass:"long"},[_c('a',{attrs:{"href":"mailto:info@reallygood.jp"}},[_vm._v("お問い合わせ")])])},function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('td',{staticClass:"short"},[_c('a')])},function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('td',{staticClass:"short"},[_c('a')])},function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('td',{staticClass:"long"},[_c('a')])},function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"copyright wrap1100"},[_c('p',{staticStyle:{"line-height":"1.5em"}},[_vm._v(" 古物商許可証 愛知県公安委員会"),_c('br',{staticClass:"sp-kaigyo"}),_vm._v("第541162211500号"),_c('br'),_vm._v("株式会社ロカンタン ")]),_c('p',[_vm._v(" Copyright © "),_c('br',{staticClass:"sp-kaigyo"}),_vm._v("Really Good All Rights Reserved. ")])])}]

export { render, staticRenderFns }