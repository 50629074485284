<template>
  <div class="contact-contact">
    <div v-if="pageType === 'input'">
      <h1>お問い合わせフォーム</h1>
    </div>
    <div v-if="pageType === 'confirm'">
      <h1>お問い合わせ内容確認画面</h1>
    </div>
    <div v-if="pageType === 'complete'">
      <h1>お問い合わせを承りました</h1>
    </div>

    <template v-if="pageType === 'input'">
      <div class="contact-cover">
        <div class="contact-item">
          <div class="left"><p>お名前<span class="red">(必須)</span></p></div>
          <div class="right">
            <input v-model="contactData.name" type="text" placeholder="例）山田 太郎"/>
          </div>
        </div>
        <DiplayValidationErrorMessages
          :messageList="validationErrorData.name"
          class="note"
        />

        <div class="contact-item">
          <div class="left"><p>メールアドレス<span class="red">(必須)</span></p></div>
          <div class="right">
            <input v-model="contactData.email" type="email" placeholder="例）sample@gmail.com" />
          </div>
        </div>
        <DiplayValidationErrorMessages
          :messageList="validationErrorData.email"
          class="note"
        />

        <div class="contact-item">
          <div class="left">メールアドレス<span>（確認用）</span></div>
          <div class="right">
            <input v-model="contactData.emailConfirmation" type="email" />
          </div>
          <DiplayValidationErrorMessages
            :messageList="validationErrorData.emailConfirmation"
            class="note"
          />
        </div>

        <div class="contact-item">
          <div class="left">電話番号<span class="red">(必須)</span></div>
          <div class="right tel">
            <input v-model="contactData.tel1" type="tel" placeholder="000"/> -
            <input v-model="contactData.tel2" type="tel" placeholder="1234"/> -
            <input v-model="contactData.tel3" type="tel" placeholder="5678"/>
          </div>
        </div>
        <DiplayValidationErrorMessages
          :messageList="validationErrorData.tel1"
          class="note"
        />
        <DiplayValidationErrorMessages
          class="note"
          :messageList="validationErrorData.tel2"
        />
        <DiplayValidationErrorMessages
          class="note"
          :messageList="validationErrorData.tel3"
        />

        <div class="contact-item">
          <div class="left">買取方法<span class="red">(必須)</span></div>
          <div class="right">
            <el-radio-group v-model="contactData.buyingType">
              <el-radio
                v-for="(buyingTypeData, buyingTypeIndex) in buyingTypeList"
                :key="buyingTypeIndex"
                :label="buyingTypeData.id"
                 style="padding:10px 0;"><span style="font-size: 20px">{{
                  buyingTypeData.name
                }}</span></el-radio
              >
            </el-radio-group>
          </div>
        </div>
        <DiplayValidationErrorMessages
          :messageList="validationErrorData.buyingType"
          class="note"
        />
      </div>
      <!-- </template> -->

      <template
        v-if="contactData.buyingType === '0' || contactData.buyingType === '1'"
      >
        <div class="contact-cover">
          <div class="contact-item">
            <div class="left">住所</div>
            <div class="sub">〒</div>
            <div class="right tel">
              <input v-model="contactData.postalCode1" type="text" /> -
              <input v-model="contactData.postalCode2" type="text" />
              <button
                style="width: 200px; height: 50px; margin-left: 10px"
                @click="getAddressData"
              >
                住所検索
              </button>
            </div>
          </div>
          <DiplayValidationErrorMessages
            :messageList="validationErrorData.postalCode1"
            class="note"
          />
          <DiplayValidationErrorMessages
            :messageList="validationErrorData.postalCode2"
            class="note"
          />

          <div class="contact-item">
            <div class="left kara"></div>
            <div class="sub">都道府県</div>
            <div class="right address">
              <select v-model="contactData.prefCode">
                <option :value="''">選択してください</option>
                <option
                  v-for="(prefData, prefIndex) in prefList"
                  :key="prefIndex"
                  :value="prefData.prefCode"
                >
                  {{ prefData.prefName }}
                </option>
              </select>
            </div>
          </div>
          <DiplayValidationErrorMessages
            :messageList="validationErrorData.prefCode"
            class="note"
          />

          <div class="contact-item">
            <div class="left kara"></div>
            <div class="sub">市区町村</div>
            <div class="right address">
              <input v-model="contactData.address1" type="text" />
            </div>
          </div>
          <DiplayValidationErrorMessages
            :messageList="validationErrorData.address1"
            class="note"
          />

          <div class="contact-item">
            <div class="left kara"></div>
            <div class="sub">町名番地等</div>
            <div class="right address">
              <input v-model="contactData.address2" type="text" />
            </div>
          </div>
          <DiplayValidationErrorMessages
            :messageList="validationErrorData.address2"
            class="note"
          />
          <div class="contact-item">
            <div class="left kara"></div>
            <div class="sub">建物名</div>
            <div class="right address">
              <input v-model="contactData.address3" type="text" />
            </div>
          </div>
          <DiplayValidationErrorMessages
            :messageList="validationErrorData.address3"
            class="note"
          />
        </div>

        <template v-if="contactData.buyingType === '0'">
          <div class="contact-cover">
            <div class="contact-item">
              <div class="left"><p>梱包キット</p></div>
              <div class="right tel">
                <el-radio-group v-model="contactData.kit">
                  <el-radio :label="true"
                    ><span style="font-size: 20px"> 希望する</span></el-radio
                  >
                  <el-radio :label="false"
                    ><span style="font-size: 20px">希望しない</span></el-radio
                  >
                </el-radio-group>
              </div>
              <div></div>
              <DiplayValidationErrorMessages
                :messageList="validationErrorData.kit"
                class="note"
              />
            </div>
          </div>
        </template>
      </template>
      <div class="contact-cover">
        <div class="contact-item">
          <div class="left"><p>商品の写真</p></div>
          <div class="right tel" style="flex-direction: column;">
              <p style="text-align:left;font-size:15px;width:100%;color:gray;">※有を選択すると3枚まで添付出来ます。</p>
            <el-radio-group v-model="contactData.sendImage" style="height:40px;text-align:left;width:100%;">
              <el-radio :label="false"
                ><span style="font-size: 20px">無</span></el-radio
              >
              <el-radio :label="true"
                ><span style="font-size: 20px"> 有</span></el-radio
              >
            </el-radio-group>
          </div>
            <DiplayValidationErrorMessages
              :messageList="validationErrorData.sendImage"
              class="note"
            />
         
        </div>
      </div>
      <template v-if="contactData.sendImage === true">
        <div class="contact-cover">
          <div class="contact-item">
            <div class="left"><p>商品の写真１</p></div>
            <div class="right tel">
              <input
                v-if="uploadFile1 === null"
                type="file"
                @change="uploadFileSelected1"
              />
              <button v-else @click="uploadFile1 = null">解除</button>
            </div>
          </div>
          <DiplayValidationErrorMessages
            :messageList="validationErrorData.uploadFile1"
            class="note"
          />
          <div class="contact-item">
            <div class="left"><p>商品の写真２</p></div>
            <div class="right tel">
              <input
                v-if="uploadFile2 === null"
                type="file"
                @change="uploadFileSelected2"
              />
              <button v-else @click="uploadFile2 = null">解除</button>
            </div>
          </div>
          <DiplayValidationErrorMessages
            :messageList="validationErrorData.uploadFile2"
            class="note"
          />
          <div class="contact-item">
            <div class="left"><p>商品の写真３</p></div>
            <div class="right tel">
              <input
                v-if="uploadFile3 === null"
                type="file"
                @change="uploadFileSelected3"
              />
              <button v-else @click="uploadFile3 = null">解除</button>
            </div>
          </div>
          <DiplayValidationErrorMessages
            :messageList="validationErrorData.uploadFile3"
            class="note"
          />
        </div>
      </template>
      <div class="contact-cover">
        <div class="contact-item">
          <div class="left"><p>備考・お問い合わせ内容</p></div>
          <div class="right" style="flex-direction: column;">
            <p style="text-align:left;font-size:15px;width:100%;color:gray;">買取をご希望の際は、買取品目や量などをご入力ください。</p>
            <textarea v-model="contactData.remarks" placeholder="クラシックやジャズのレコード、約200枚、段ボール3箱分"></textarea>
          </div>
        </div>
        <DiplayValidationErrorMessages
          :messageList="validationErrorData.remarks"
          class="note"
        />
        <div class="contact-item">
          <div class="left"><p>個人情報取扱い</p></div>
            <div style="flex-direction: column;">
               <p style="text-align:left;font-size:15px;width:100%;color:gray;">送信するには個人情報の取扱いについてへの同意が必要です。
          </p>
          <div class="right check" >
            <input v-model="contactData.agree" type="checkbox" />同意する
            <router-link to="/privacy" target="_blank"
              ><span style="margin-left: 10px"
                >個人情報保護ポリシー</span
              ></router-link
            >
          </div>
        </div>
        </div>
        <DiplayValidationErrorMessages
          :messageList="validationErrorData.agree"
          class="note"
        />
      </div>

      <div
        v-show="validationError === true"
        style="
          text-align: center;
          color: red;
          font-size: 16px;
          margin-left: 30px;
        "
      >
        ご入力内容をご確認ください。
      </div>
      <div class="button-cover">
        <el-button @click="confirm" type="primary">内容確認画面へ</el-button>
      </div>
    </template>
    <!-- ↑↑ 「/contact-form」にある想定 ↑↑ -->

    <!-- ↓↓ 「/contact-form-confirm」にある想定 ↓↓ -->
    <template v-if="pageType === 'confirm'">
      <div class="contact-cover">
        <div class="contact-item">
          <div class="left"><p>お名前</p></div>
          <div class="right">
            {{ contactData.name }}
          </div>
        </div>

        <div class="contact-item">
          <div class="left"><p>メールアドレス</p></div>
          <div class="right">
            {{ contactData.email }}
          </div>
        </div>

        <div class="contact-item">
          <div class="left">メールアドレス<span>（確認用）</span></div>
          <div class="right">
            {{ contactData.email }}
          </div>
        </div>

        <div class="contact-item">
          <div class="left">電話番号</div>
          <div class="right tel">
            {{ contactData.tel1 }} - {{ contactData.tel2 }} -
            {{ contactData.tel3 }}
          </div>
        </div>

        <div class="contact-item">
          <div class="left">買取方法</div>
          <div class="right">
            {{ buyingTypeName(contactData.buyingType) }}
          </div>
        </div>
      </div>

      <template
        v-if="contactData.buyingType === '0' || contactData.buyingType === '1'"
      >
        <div class="contact-cover">
          <div class="contact-item">
            <div class="left">住所</div>
            <div class="confirm-type">
              <div class="sub post-code">〒</div>
              <div class="right" style="width: 250px">
                {{ contactData.postalCode1 }} -
                {{ contactData.postalCode2 }}
              </div>
            </div>
          </div>

          <div class="contact-item">
            <div class="left kara"></div>
            <!-- <div class="sub">都道府県</div> -->
            <div class="right address">
              {{ prefName(contactData.prefCode) }}&emsp;{{
                contactData.address1
              }}&emsp;{{ contactData.address2 }}&emsp;{{ contactData.address3 }}
            </div>
          </div>
        </div>

        <template v-if="contactData.buyingType === '0'">
          <div class="contact-cover">
            <div class="contact-item">
              <div class="left"><p>梱包キット</p></div>
              <div class="right tel">
                <span v-if="contactData.kit === true">希望する</span
                ><span v-else-if="contactData.kit === false">希望しない</span>
              </div>
            </div>
          </div>
        </template>
      </template>
      <div class="contact-cover">
        <div class="contact-item">
          <div class="left"><p>商品の写真</p></div>
          <div class="right tel">
            <span v-if="contactData.sendImage === false">無</span
            ><span v-else-if="contactData.sendImage === true"> 有 </span>
          </div>
        </div>
      </div>

      <template v-if="contactData.sendImage === true">
        <div class="contact-cover">
          <div class="contact-item">
            <div class="left"><p>商品の写真１</p></div>
            <div class="right tel">
              <span v-if="uploadFile1 === null">無</span
              ><span v-else> 添付されています </span>
            </div>
          </div>
          <div class="contact-item">
            <div class="left"><p>商品の写真２</p></div>
            <div class="right tel">
              <span v-if="uploadFile2 === null">無</span
              ><span v-else> 添付されています </span>
            </div>
          </div>
          <div class="contact-item">
            <div class="left"><p>商品の写真３</p></div>
            <div class="right tel">
              <span v-if="uploadFile3 === null">無</span
              ><span v-else> 添付されています </span>
            </div>
          </div>
        </div>
      </template>
      <div class="contact-cover">
        <div class="contact-item">
          <div class="left"><p>備考・お問い合わせ内容</p></div>
          <div class="right">
            {{ contactData.remarks }}
          </div>
        </div>
        <div class="contact-item">
          <div class="left"><p>個人情報取扱い</p></div>
          <div class="right">同意する</div>
        </div>
      </div>
      <div class="button-cover">
        <el-button @click="pageType = 'input'" type="info">戻る</el-button>
        <el-button @click="send" type="primary">送信</el-button>
      </div>
    </template>
    <!-- ↑↑ 「/contact-form-confirm」にある想定 ↑↑ -->

    <!-- ↓↓ 「/contact-form-complete」にある想定 ↓↓ -->
    <template v-if="pageType === 'complete'">
      <p style="margin-top: 200px; margin-bottom: 100px">
        お問い合わせを承りました。<br />お問い合わせ内容を、ご入力のメールアドレスに送信しました。20分経過しても届かない場合は、迷惑メールボックスの中をご確認ください。
      </p>
      <div class="button-cover">
        <router-link :to="`/`">
          <el-button type="danger">トップに戻る</el-button></router-link
        >
      </div>
    </template>
    <!-- ↑↑ 「/contact-form-complete」にある想定 ↑↑ -->
  </div>
</template>

<script>
import $ from "jquery";
import { mapGetters } from "vuex";
import DiplayValidationErrorMessages from "@/components/Ui/DisplayValidationErrorMessages.vue";

export default {
  name: "test-contact",
  components: {
    DiplayValidationErrorMessages,
  },
  props: {},
  data() {
    return {
      pageType: "input",
      // pageType: "confirm",
      contactData: {
        name: "",
        email: "",
        emailConfirmation: "",
        tel1: "",
        tel2: "",
        tel3: "",
        buyingType: "",
        postalCode1: "",
        postalCode2: "",
        prefCode: "",
        address1: "",
        address2: "",
        address3: "",
        kit: true,
        sendImage: false,
        remarks: "",
        agree: true,
      },
      // contactData: {
      //   name: "てすと太郎",
      //   email: "yusuke32123@gmail.com",
      //   emailConfirmation: "yusuke32123@gmail.com",
      //   tel1: "080",
      //   tel2: "1234",
      //   tel3: "3214",
      //   buyingType: "0",
      //   postalCode1: "123",
      //   postalCode2: "4567",
      //   prefCode: "39",
      //   address1: "南国市蛍が丘",
      //   address2: "3-7-2",
      //   address3: "ビバリーヒルズ",
      //   kit: false,
      //   sendImage: true,
      //   remarks: "特にありません",
      //   agree: true,
      // },
      uploadFile1: null,
      uploadFile2: null,
      uploadFile3: null,
      validationError: false,
      validationErrorData: {
        name: [],
        email: [],
        emailConfirmation: [],
        tel1: [],
        tel2: [],
        tel3: [],
        buyingType: [],
        postalCode1: [],
        postalCode2: [],
        prefCode: [],
        address1: [],
        address2: [],
        address3: [],
        kit: [],
        sendImage: [],
        uploadFile1: [],
        uploadFile2: [],
        uploadFile3: [],
        remarks: [],
        agree: [],
      },
    };
  },
  computed: {
    ...mapGetters(["rootUrl", "prefList", "buyingTypeList"]),
  },
  methods: {
    // ↓↓ 「/contact-form」にある想定 ↓↓
    uploadFileSelected1(event) {
      this.uploadFile1 = event.target.files[0];
      this.$forceUpdate();
    },
    uploadFileSelected2(event) {
      this.uploadFile2 = event.target.files[0];
      this.$forceUpdate();
    },
    uploadFileSelected3(event) {
      this.uploadFile3 = event.target.files[0];
      this.$forceUpdate();
    },
    confirm() {
      const formData = new FormData();
      formData.append("contact_data", JSON.stringify(this.contactData));
      formData.append("upload_file1", this.uploadFile1);
      formData.append("upload_file2", this.uploadFile2);
      formData.append("upload_file3", this.uploadFile3);
      this.$axios
        .post(`${this.rootUrl}/api/contact/validate-inputs`, formData)
        .then((response) => {
          console.log(response);
          if (response.data.status === 200) {
            this.pageType = "confirm";
            window.scroll({ top: 0, behavior: "smooth" });
            // 本来は「this.$router.push('/contact-form-confirm');」
            this.validationErrorData = {};
            return;
          } else if (response.data.status === 400) {
            this.validationError = true;
            this.validationErrorData = response.data.validationErrorData;
            return;
          } else if (response.data.status === 401) {
            alert("変更できませんでした。");
            return;
          } else {
            alert("エラーが発生しました。");
            return;
          }
        })
        .catch((err) => {
          console.log(err);
        });
    },
    getAddressData() {
      let postalCode =
        this.contactData.postalCode1 + this.contactData.postalCode2;
      $.ajax({
        url: `https://zipcloud.ibsnet.co.jp/api/search`,
        data: { zipcode: postalCode },
        dataType: "jsonp",
        success: function (response) {
          console.log(response);
          if (response.status === 200) {
            this.contactData.prefCode = response.results[0].prefcode;
            this.contactData.address1 =
              response.results[0].address1 +
              response.results[0].address2 +
              response.results[0].address3;
          } else {
            // alert(response.message);
          }
        }.bind(this),
      });
    },
    // ↑↑ 「/contact-form」にある想定 ↑↑

    // ↓↓ 「/contact-form-confirm」にある想定 ↓↓
    buyingTypeName(buyingType) {
      return this.$store.getters.buyingTypeData(buyingType).name;
    },
    prefName(prefCode) {
      return this.$store.getters.prefData(prefCode).prefName;
    },
    send() {
      const formData = new FormData();
      formData.append("contact_data", JSON.stringify(this.contactData));
      formData.append("upload_file1", this.uploadFile1);
      formData.append("upload_file2", this.uploadFile2);
      formData.append("upload_file3", this.uploadFile3);
      this.$axios
        .post(`${this.rootUrl}/api/contact/send-mail`, formData)
        .then((response) => {
          console.log(response);
          if (response.data.status === 200) {
            this.pageType = "complete";
            window.scroll({ top: 0, behavior: "smooth" });
            this.validationErrorData = {};
            return;
          } else if (response.data.status === 400) {
            this.validationErrorData = response.data.validationErrorData;
            return;
          } else if (response.data.status === 401) {
            alert("変更できませんでした。");
            return;
          } else {
            alert("エラーが発生しました。");
            return;
          }
        })
        .catch((err) => {
          console.log(err);
        });
    },
    // ↑↑ 「/contact-form-confirm」にある想定 ↑↑
  },
};
</script>

<style lang="scss" scoped>
h1 {
  margin: 50px 0;
}
.contact-cover {
  .contact-item {
    display: flex;
    padding: 10px 0;
    @media screen and (max-width: 768px) {
      flex-direction: column;
    }
    .left {
      min-width: 250px;
      font-size: 17px;
      background-color: #eeeeee;
      margin-right: 10px;
      padding: 10px 15px;
      @media screen and (max-width: 768px) {
        width: 100%;
      }
      p {
         vertical-align: middle;
      }
      span {
        font-size: 15px;
      }
    }
    .kara {
      background-color: white;
      @media screen and (max-width: 768px) {
        display: none;
      }
    }
    .sub {
      width: 130px;
    }
    .post-code {
      width: 30px;
      display: flex;
      align-items: center;
      padding-left: 10px;
      @media screen and (max-width: 820px) {
        margin-top: 10px;
      }
    }
    .confirm-type {
      display: flex;
    }
    .right {
      width: calc(100% - 260px);
      display: flex;
      align-items: center;
      padding-left: 10px;
      @media screen and (max-width: 768px) {
        width: 95%;
        padding-left: 10px;
        margin-top: 10px;
      }
      input,
      select,
      textarea {
        font-size: 16px;
        width: 100%;
        padding-left: 10px;
        height: 60px;
        @media screen and (max-width: 768px) {
          height: 60px;
        }
      }
      textarea {
        min-height: 200px;
        padding: 10px;
        
      }

      el-radio-group {
        .el-radio__inner {
          width: 20px;
        }
      }
    }
    .tel {
      width: 40%;
      @media screen and (max-width: 820px) {
        width: 80%;
      }
    }
    .address {
      width: 50%;
      @media screen and (max-width: 768px) {
        width: 100%;
      }
    }
    .check {
      width: 300px;
      input {
        width: 30px;
        margin-right: 10px;
      }
      font-size: 15px;
    }
  }
}
.note {
  text-align: right;
}
.red{
  color: red;
  margin-left: 5px;
  vertical-align: middle;
}
</style>