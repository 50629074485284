<template>
  <div class="ctrl wrap1100 space-100">
    <h1>管理画面</h1>
    <div class="panel">
      <div class="log-out">
        <CtrlLogout />
      </div>

      <div class="login">
        <router-link to="/ctrl/group-member">
          <el-button type="danger" plain
            >カテゴリページ別の買取強化品目</el-button
          ></router-link
        >

        <router-link to="/ctrl/article">
          <el-button type="success" plain>買取情報記事</el-button></router-link
        >

        <router-link to="/ctrl/product">
          <el-button type="primary" plain
            >買取情報記事への掲載商品</el-button
          ></router-link
        >
        <router-link to="/ctrl/results">
          <el-button plain type="warning"
            >全ページ共通の買取実績
          </el-button></router-link
        >
        <router-link to="/ctrl/change-password">
          <el-button plain>パスワード変更</el-button></router-link
        >
      </div>
    </div>
  </div>
</template>

<script>
import { mapGetters } from "vuex";
import CtrlLogout from "@/components/Ctrl/Logout.vue";

export default {
  name: "ctrlhome",
  components: {
    CtrlLogout,
  },
  props: {},
  data() {
    return {};
  },
  computed: {
    ...mapGetters(["rootUrl"]),
  },
  watch: {},
  methods: {
    logout() {
      this.$axios
        .get(`${this.rootUrl}/api/logout.php`)
        .then((response) => {
          console.log(response);
          if (response.data.status === 200) {
            this.$router.push("/ctrl/login");
            return;
          } else {
            alert("エラーが発生しました。");
            return;
          }
        })
        .catch((err) => {
          console.log(err);
        });
    },
  },
};
</script>

<style lang="scss" scoped>
.panel {
  height: 500px;
  background-color: #f5f5f5;
  padding: 50px 100px;
  margin-bottom: 50px;
  margin-top: 70px;
  @media screen and (max-width: 767px) {
    padding: 30px 0;
  }
}
.login {
  display: flex;
  justify-content: space-between;
  flex-wrap: wrap;
  @media screen and (max-width: 767px) {
    justify-content: center;
  }
  .el-button {
    width: 400px;
    margin-bottom: 20px;
    @media screen and (max-width: 767px) {
      width: 300px;
      margin-bottom: 40px;
    }
  }
}
h1 {
  text-align: center;
  color: var(--black);
  border-bottom: 1px solid var(--gray);
  padding-bottom: 15px;
  font-size: 22px;
}
.log-out {
  display: flex;
  align-items: center;
  justify-content: flex-end;
  margin-bottom: 50px;
  .el-button {
    width: 150px;
  }
}
</style>