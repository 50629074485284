<template>
  <div class="classic-record">
    <div class="top">
      <img alt="really-goodトップ" :src="`${rootUrl}/img/reallygood-pc-top-classic.jpg`" class="pc" />
      <img alt="really-goodトップ" :src="`${rootUrl}/img/reallygood-sp-top-classic.jpg`" class="sp" />
    </div>
    <div class="back">
      <HomeGallery />
    </div>
    <div class="wrap960">
      <p class="top-text">
        クラシックレコードの買取なら、レコード専門店であり、かつ、クラシックレコードに特化したリアリーグッドをご利用ください。専門店だからこそできる適切な査定で高価買取を実現しています。お気軽にまずは査定からご依頼くださいませ。
      </p>
    </div>

    <img alt="デザイントップ" :src="`${rootUrl}/img/reallygood-top3.png`" class="design" />
    <div style="background-color: #fff9e9" class="spcae3">
      <HomeAchievement />
    </div>
    <img alt="デザインボトム" :src="`${rootUrl}/img/reallygood-bottom3.png`" class="design" />

    <div class="space-50 strong">
      <UiStrongItem :categoryId="`1`" />
    </div>


    <div class="fixed-back space-150" :style="`background-image: url(${rootUrl}/img/back-img02.jpg);`">
      <ClassicRecordReason />
    </div>

    <div style="background-color: #fffef7" class="sapce-150">
      <div class="wrap960">
        <UiAssessmentPoints :pageType="`classic`" />
      </div>
    </div>

    <!-- <div class="wrap960 space-150">
      <ClassicRecordStrongList />
    </div> -->

    <img alt="デザイントップ" :src="`${rootUrl}/img/reallygood-top3.png`" class="design margin-top" />

    <div style="background-color: #fff9e9" class="spcae3 buy">
      <div class="wrap960">
        <HomeHowToBuy :shopId="null" />
      </div>
    </div>
    <img alt="デザインボトム" :src="`${rootUrl}/img/reallygood-bottom3.png`" class="design margin-bottom" />

    <div class="space-50">
      <HomeAboutUs :displayShopData="true" />
    </div>

    <div style="background-color: #fffef7" class="sapce-150">
      <div class="wrap1100">
        <ClassicRecordCustomersVoice />
      </div>
    </div>

    <div class="wrap960 space-100">
      <HomeQa />
    </div>

    <div class="space-100 wrap960">
      <HomePromise />
    </div>

    <div class="wrap960">
      <UiColumn :pageType="`classic`" :categoryId="`1`" />
    </div>
  </div>
</template>

<script>
import { mapGetters } from "vuex";
import HomeGallery from "@/components/Home/Gallery.vue";
import HomeAchievement from "@/components/Home/Achievement.vue";
import ClassicRecordReason from "@/components/ClassicRecord/Reason.vue";
import UiAssessmentPoints from "@/components/Ui/AssessmentPoints.vue";
// import ClassicRecordStrongList from "@/components/ClassicRecord/StrongList.vue";
import HomeHowToBuy from "@/components/Home/HowToBuy.vue";
import HomeAboutUs from "@/components/Home/AboutUs.vue";
import ClassicRecordCustomersVoice from "@/components/ClassicRecord/CustomersVoice.vue";
import HomeQa from "@/components/Home/Qa.vue";
import HomePromise from "@/components/Home/Promise.vue";
import UiColumn from "@/components/Ui/Column.vue";
import UiStrongItem from "@/components/Ui/StrongItem.vue";

export default {
  name: "ClassicRecord",
  components: {
    HomeGallery,
    HomeAchievement,
    ClassicRecordReason,
    UiAssessmentPoints,
    // ClassicRecordStrongList,
    HomeHowToBuy,
    HomeAboutUs,
    ClassicRecordCustomersVoice,
    HomePromise,
    HomeQa,
    UiColumn,
    UiStrongItem,
  },
  props: {},
  data() {
    return {};
  },
  computed: {
    ...mapGetters(["rootUrl"]),
  },
  watch: {},
  methods: {},
};
</script>
<style lang="scss" scoped>
.top {
  margin: 0 auto;
  max-width: 1100px;
  width: 100vw;

  @media screen and (max-width: 768px) {
    width: 100%;
    margin: 20px 0;
  }

  .pc {
    width: 95%;
    margin-top: 70px;

    @media screen and (max-width: 768px) {
      display: none;
    }
  }

  .sp {
    display: none;

    @media screen and (max-width: 768px) {
      display: initial;
      width: 100%;
    }
  }

}

.back {
  padding-top: 10px;
  padding-bottom: 50px;
  background-size: cover;
  background-repeat: no-repeat;
}

.top-text {
  font-size: 20px;
  margin-bottom: 100px;

  @media screen and (max-width: 768px) {
    font-size: 18px;
  }
}

.design {
  width: 100vw !important;
  vertical-align: top;
}

.spcae1 {
  padding-bottom: 250px;
}

.spcae3 {
  padding-bottom: 50px;
}

.fixed-back {
  position: relative;
  // min-height: 100%;
  // height: 1350px; /*--スクロールをさせるため 高さ指定--*/
  background-size: cover;
  /*--背景画像のサイズ--*/
  // background-attachment: fixed; /*--背景画像の固定--*/
  background-repeat: no-repeat;
  /*--背景画像の繰り返し--*/
  background-position: center center;
  /*--背景画像の位置--*/
  z-index: 2;
  padding: 5em 0;

  @media screen and (max-width: 1024px) {
    background-attachment: initial;
    width: 100%;
    // background-size: auto 100%;
  }
}

.fixed-bottom {
  padding-bottom: 50px;

  @media screen and (max-width: 768px) {
    padding-bottom: 20px;
  }
}

.margin-top {
  margin-top: 150px;
}

.margin-bottom {
  margin-bottom: 150px;
}

.strong {
  @media screen and (max-width: 768px) {
    padding-top: 50px;
  }
}

.buy {
  @media screen and (max-width: 768px) {
    padding-bottom: 100px;
  }
}
</style>
