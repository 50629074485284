<template>
  <div class="home-customers-voice">
    <div class="title-cover space-150">
      <img :src="`${rootUrl}/img/title04.png`" alt="お客様の声" />
    </div>
    <p class="center space-50 top">
      当店をご利用いただいたお客様の声をご紹介します。
    </p>
    <div class="box-voice-cover space-100">
      <div class="box-voice">
        <UiBoxVoice
          :title="`オープンリールの専門店は<br>少ないので相談しやすかった`"
          :subTitle="`ミュージックテープ20点、空リールを５点を28,000円で買取させて頂きました`"
          :detail="`ネットでオープンリールテープの買取をしている店舗を探したら、リアリーグッドさんのホームページを見つけ、専門店とのことだったので依頼しました。<br>近くのリサイクルショップや音楽店では買取してもらえなかったので、値段もしっかりつけていただけて良かったです。`"
          :target="`愛知県 / 50代 / 男性`"
          class="box"
        />
                <UiBoxVoice
          :title="`レコードの出張引取の時に<br>一緒に買取ってくれました`"
          :subTitle="`ミュージックテープ20点、空リールを５点を28,000円で買取させて頂きました`"
          :detail="`祖父の遺品のレコードの出張買取に来てもらった時に、どういうものかわからず処分に困っていると伝えたら丁寧に説明してくださって、一緒に引き取ってもらうことにしました。<br>思ったより値段がついたので驚きました。`"
          :target="`三重県 / 30代 / 女性`"
          class="box"
        />

       
      </div>

      <div class="box-voice">

         <UiBoxVoice
          :title="`使える分からず、処分も考えましたが`"
          :subTitle="`オープンリールデッキ、付属品など5点、40,000円で買取させて頂きました`"
          :detail="`長いこと使用していなかったオープンデッキがあり、故障している可能性が高かったため処分に悩んでいました。ネットで調べたところ、リアリーグッドさんの買取リストに載っていた機種だったため電話で相談してみました。近くだったこともあり、直ぐに出張買取に来て下さいました。動作テストをして頂き、一部動作不良があり減額がありましたが、十分な金額を提示して頂き、安心、納得の買取でした。`"
          :target="`愛知県/ 70代 / 男性`"
          class="box"
        />

        <UiBoxVoice
          :title="`まとめて買取ってもらいました`"
          :subTitle="`オープンリールテープからその他35点、22,000円で買取させて頂きました`"
          :detail="`オープンリールで使用するものか分からないものもありましたが、先に写真を撮りメールを送ったところ、丁寧に説明して頂き、すべて値段もつくということだったので送りました。カセットテープなど一部違うものも含まれていたようでしたが、すべて買取ってもらうことが出来て良かったです。`"
          :target="`長崎県/ 50代 / 女性`"
          class="box"
        />
      </div>
    </div>
  </div>
</template>

<script>
import { mapGetters } from "vuex";
import UiBoxVoice from "@/components/Ui/BoxVoice.vue";
export default {
  name: "home-if-you-buy",
  components: {
    UiBoxVoice,
  },
  props: {},
  data() {
    return {};
  },
  computed: {
    ...mapGetters(["rootUrl"]),
  },
};
</script>

<style lang="scss" scoped>
.home-customers-voice {
    padding-bottom: 100px;
  @media screen and (max-width: 768px) {
    padding-bottom: 30px;
  }
  .title-cover {
    img {
      margin-top: 100px;
      @media screen and (max-width: 768px) {
        margin-top: 50px;
      }
    }
  }
  .box-voice {
    display: flex;
    justify-content: space-between;
    align-items: flex-start;
    @media screen and (max-width: 1024px) {
      flex-direction: column;
    }

    .box {
      width: 49%;
      @media screen and (min-width: 581px) and (max-width: 1024px) {
        width: 70%;
        margin-left: auto;
        margin-right: auto;
      }
      @media screen and (max-width: 580px) {
        width: 100%;
      }
    }
  }
}
</style>