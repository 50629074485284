<template>
  <div class="test-register-product wrap960 space-100">
    <h1>
      <!-- {{ categoryData.name }}<br /> -->
      買い取り実績 追加・編集
    </h1>

    <div class="record-box">
      <div class="item-box-cover">
        <draggable
          v-model="resultList"
          element="div"
          :options="{ animation: 300 }"
          class="doraggu"
        >
          <div
            class="item-box"
            v-for="(resultData, resultIndex) in resultList"
            :key="resultIndex"
          >
            <div class="product-list">
              <div class="title-cover">
                <p>{{ resultData.title }}</p>
                <div class="category">
                  <p>{{ getCategoryName(resultData.categoryId) }}</p>
                </div>
              </div>
              <div class="button-cover2">
                <router-link :to="`/ctrl/result-detail/${resultData.id}`">
                  <el-button
                    type="primary"
                    style="
                      vertical-align: top;
                      height: 100%;
                      margin-right: 10px;
                    "
                    >確認・修正</el-button
                  ></router-link
                >
                <el-button @click="deleteResult(resultData.id)">削除</el-button>
                <div>
                  <el-checkbox
                    v-model="resultData.visible"
                    style="margin-left: 5px"
                  />
                  <!-- <span>TOPに反映</span> -->
                </div>
              </div>
            </div>
          </div>
        </draggable>
      </div>

      <div class="button-cover">
        <el-button @click="back" type="info" class="back">戻る</el-button>
        <router-link :to="`/ctrl/result-detail/0`">
          <el-button type="danger" class="new-button"
            >新規作成</el-button
          ></router-link
        >
        <el-button @click="registerVisbleConfig()">表示設定登録</el-button>
      </div>
    </div>
  </div>
</template>

<script>
import { mapGetters } from "vuex";
import draggable from "vuedraggable";

export default {
  name: "ctrl-results",
  components: {
    draggable,
  },
  props: {
    categoryId: String,
    resultId: String,
  },
  data() {
    return {
      resultList: [],
    };
  },
  computed: {
    ...mapGetters(["rootUrl"]),
    resultData() {
      return this.$store.getters.resultData(this.resultId);
    },
  },
  created() {
    this.getResultList();
  },
  methods: {
    getResultList() {
      this.resultList = this.$store.getters.resultList;
    },
    deleteResult(resultId) {
      if (confirm("本当に削除してもいいですか？")) {
        let params = new URLSearchParams();
        params.append("result_id", resultId);
        this.$axios
          .post(`${this.rootUrl}/api/result/delete-result`, params)
          .then((response) => {
            console.log(response);
            if (response.data.status === 200) {
              this.$store.dispatch("setResultList").then(response => {
                console.log(response)
                this.getResultList();
              });
              return;
            } else {
              alert("エラーが発生しました。");
              return;
            }
          })
          .catch((err) => {
            console.log(err);
          });
      } else {
        return;
      }
    },
    getCategoryName(categoryId) {
      const categoryData = this.$store.getters["categoryData"](categoryId);
      return categoryData.name;
    },
    registerVisbleConfig() {
      let visibleList = [];
      let sort = 0;
      this.resultList.forEach((resultData) => {
        visibleList.push({
          resultId: resultData.id,
          visible: resultData.visible === true ? 1 : 0,
          sort: sort,
        });
        sort++;
      });
      console.log(visibleList);

      if (
        visibleList.filter((visibleData) => visibleData.visible === 1).length <=
        20
      ) {
        //
      } else {
        alert("表示は20個までにしてください。");
        return;
      }

      //
      let params = new URLSearchParams();
      params.append("visible_list", JSON.stringify(visibleList));
      this.$axios
        .post(`${this.rootUrl}/api/result/register-visible-config`, params)
        .then((response) => {
          console.log(response);
          if (response.data.status === 200) {
            alert("変更しました。");
            this.$store.dispatch("setResultList");
            return;
          } else if (
            response.data.status === 401 ||
            response.data.status === 400
          ) {
            alert(response.data.message);
            return;
          } else {
            alert("エラーが発生しました。");
            return;
          }
        })
        .catch((err) => {
          console.log(err);
        });
    },
    back() {
      this.$router.go(-1);
    },
  },
};
</script>

<style lang="scss" scoped>
.record-box {
  display: flex;
  flex-direction: column;
  align-items: center;
  margin: 50px 0;
  .sub-title {
    border-bottom: 1px solid black;
    margin-bottom: 30px;
    img {
      width: 50px;
      vertical-align: middle;
      margin-right: 10px;
    }
    span {
      font-weight: 700;
      vertical-align: middle;
      font-size: 36px;
    }
  }
  .item-box-cover {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    width: 100%;
    margin: 0 auto;
  }
  .product-list {
    background-color: #f7f7f7;
    padding: 10px 20px;
    display: flex;
    justify-content: space-between;
    width: 100%;
    margin-bottom: 30px;
    cursor: grab;
    &:active {
      cursor: grabbing;
    }
    @media screen and (max-width: 768px) {
      flex-direction: column;
    }
    .title-cover {
      display: flex;
      flex-direction: row;
      p {
        // width: 100%;
        white-space: nowrap;
        @media screen and (max-width: 768px) {
          text-align: center;
        }
      }
      .category {
        border: 2px solid var(--green);
        white-space: nowrap;
        font-size: 12px;
        border-radius: 5px;
        margin-left: 10px;
        padding: 0 10px;
        max-width: 150px;
        text-align: center;
        height: 30px;
        display: flex;
        justify-content: center;
        align-items: center;
        color: var(--green);
      }
    }
    .button-cover2 {
      display: flex;
      justify-content: flex-end;
      align-items: center;
      width: 73%;
      @media screen and (max-width: 768px) {
        justify-content: space-between;
        margin-top: 20px;
      }
    }
  }
  .explanation {
    // background-color: var(--yellow2);
    // border-bottom: 2px var(--yellow2) solid;
    width: 40%;
    text-align: center;
    margin: 0 auto;
    margin-bottom: 30px;
  }
  .bnutton-cover {
    display: flex;
    align-items: center;
    justify-content: center;
  }
  .doraggu {
    width: 100%;
  }
}
</style>