<template>
  <div class="ui-column">
    <div class="title-cover" v-if="pageType === `classic`">
      <img
        :src="`${rootUrl}/img/title10.png`"
        alt="クラシックレコードの買取コラム"
      />
    </div>
    <div class="title-cover" v-else-if="pageType === `jazz`">
      <img
        :src="`${rootUrl}/img/title13.png`"
        alt="ジャズレコードの買取コラム"
      />
    </div>
    <div class="title-cover" v-else-if="pageType === `openreel`">
      <img
        :src="`${rootUrl}/img/title16.png`"
        alt="オープンリールの買取コラム"
      />
    </div>
    <div class="title-cover" v-else-if="pageType === `wj`">
      <img
        :src="`${rootUrl}/img/title19.png`"
        alt="洋楽・邦楽の買取コラム"
      />
    </div>
    <div class="center" style="margin-top: 50px">
      <div class="navi">
        <el-pagination
          :page-size="pageSize"
          :pager-count="pagerCount"
          :total="articlesTotal"
          :current-page.sync="currentPage"
          layout="prev, pager, next"
        />
      </div>
    </div>

    <div class="space-100 list-cover">
      <ul v-for="articleData in articleList" :key="articleData.id">
        <router-link :to="`/${categoryData.code}/article/${articleData.id}`">
          <li>{{ articleData.title }}</li>
        </router-link>
      </ul>
      <div class="navi center">
        <el-pagination
          :page-size="pageSize"
          :pager-count="pagerCount"
          :total="articlesTotal"
          :current-page.sync="currentPage"
          layout="prev, pager, next"
        />
      </div>
    </div>
  </div>
</template>

<script>
import { mapGetters } from "vuex";
// import UiItemBox from "@/components/Ui/ItemBox.vue";
export default {
  name: "ui-column",
  components: {
    // UiItemBox,
  },
  props: {
    pageType: String,
    categoryId: String,
  },
  data() {
    return {
      articleList: [],
      pageSize: 10, //表示させたい数
      pagerCount: 7, //見えるページの数
      currentPage: 1, //そのページ
      articlesTotal: 0, //全記事数
    };
  },
  computed: {
    ...mapGetters(["rootUrl"]),
    categoryData() {
      return this.$store.getters.categoryData(this.categoryId);
    },
  },
  watch: {
    currentPage(newCurrentPage) {
      this.getArticleList(this.categoryId, newCurrentPage, this.pageSize);
    },
  },
  created() {
    this.getArticleList(this.categoryId, 1, this.pageSize);
  },
  methods: {
    getArticleList(categoryId, currentPage, pageSize) {
      this.$axios
        .get(
          `${this.rootUrl}/api/article/get-article-list?category_id=${categoryId}&current_page=${currentPage}&page_size=${pageSize}`
        )
        .then((response) => {
          console.log(response);
          if (response.data.status === 200) {
            this.articleList = response.data.articleList;
            this.articlesTotal = response.data.articlesTotal;
            return;
          } else if (
            response.data.status === 401 ||
            response.data.status === 400
          ) {
            alert(response.data.message);
            return;
          } else {
            alert("エラーが発生しました。");
            return;
          }
        })
        .catch((err) => {
          console.log(err);
        });
    },
  },
};
</script>

<style lang="scss" scoped>
.ui-column {
  margin-bottom: 200px;
  @media screen and (max-width: 768px) {
    margin-bottom: 100px;
  }
  .list-cover {
    padding-left: 120px;
    padding-right: 100px;
    @media screen and (max-width: 768px) {
      padding: 20px;
    }
    @media screen and (min-width: 581px) and (max-width: 768px) {
      width: 80%;
      margin-left: auto;
      margin-right: auto;
    }
    @media screen and (max-width: 580px) {
      width: 100%;
    }
    ul {
      list-style-type: disc;
      margin: 50px 0;
      line-height: 1.5em;
      li {
        @media screen and (max-width: 768px) {
          margin-left: 20px;
        }
      }
      a {
        text-decoration: none;
        color: black;
      }
      a:hover {
        cursor: pointer;
        color: var(--orange);
      }
    }
  }
}
</style>