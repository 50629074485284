<template>
  <div class="home-customers-voice">
    <div class="title-cover space-150">
      <img :src="`${rootUrl}/img/title04.png`" alt="お客様の声" />
    </div>
    <p class="center space-50" style="font-weight:bold;">
      ご利用いただいたお客様の声をご紹介します。高価買取実績が多数あります。
    </p>
    <div class="box-voice-cover space-100">
      <div class="box-voice">
      <UiBoxVoice
        :title="`引っ越しのため困っていましたが、簡単でした`"
        :subTitle="`クラシックのレコードを約800点、155,000円で買取させて頂きました`"
        :detail="`引っ越しするため、クラシック好きの父親が集めていたレコードのコレクションを処分することにしました。インターネットで調べて、色んなお店に電話しましたが、クラシックの買取はしていないと言われてしまいました。<br>何とかならないものかと相談したところ、他のお店からリアリーグッドを紹介してもらいました。電話したら、すぐ買取に来てくれて丁寧に扱ってくれた上に、買取もしっかりやってくれました。父も喜んでいます。ありがとうございました。`"
        :target="`三重県 / 30代 / 男性`"
        class="box"
      />
            <UiBoxVoice
        :title="`とても丁寧に<br>説明してくださいました`"
        :subTitle="`クラシックのレコードを約150枚、25,000円で買取させて頂きました`"
        :detail="`家の物置から昔祖父が聞いていたクラシックのレコードが段ボールに入っているのを見つけ、プレーヤーもなく聞くことが出来ないので買い取ってもらうことにしました。レコードの知識がなく売れるものかどうかも分からなかったので、電話で相談したらとても丁寧に説明してくださいました。すぐに片づけてしまいたかったので宅配でお願いしましたが、送ってからすぐに査定して連絡をくれて、振り込みしてもらうまでとってもスピーディーでした。`"
        :target="`愛知県 / 40代 / 女性`"
        class="box"
      />
      
    </div>

    <div class="box-voice">
            
            <UiBoxVoice
        :title="`専門的な知識がある`"
        :subTitle="`クラシックのレコードを約350枚、122,500円で買取させて頂きました`"
        :detail="`レコードのコレクションを整理しているが、査定スタッフはクラシックの知識があり、専門店と謳うだけあり電話で細かい相談が出来た。回答も明瞭で安心感があり、定期的に利用したいと思う。`"
        :target="`大阪府 / 70代 / 男性`"
        class="box"
      />

       <UiBoxVoice
        :title="`一枚一枚丁寧に<br>見てくれたことに感動`"
        :subTitle="`クラシックのレコードを約40枚、2,800円で買取させて頂きました`"
        :detail="`聞かなくなったレコードを処分しようと思い近くの店へ持って行ったところ、まとめてではなく一枚一枚丁寧に見てくれたことに感動を覚えこうして文字にしています。CDも良いとのことなので、また聞かなくなったら持っていきます。`"
        :target="`愛知県/ 60代 / 男性`"
        class="box"
      />

     
    </div>
  </div>
      </div>
</template>

<script>
import { mapGetters } from "vuex";
import UiBoxVoice from "@/components/Ui/BoxVoice.vue";
export default {
  name: "home-if-you-buy",
  components: {
    UiBoxVoice,
  },
  props: {},
  data() {
    return {};
  },
  computed: {
    ...mapGetters(["rootUrl"]),
  },
};
</script>

<style lang="scss" scoped>
.home-customers-voice {
    padding-bottom: 100px;
  @media screen and (max-width: 768px) {
    padding-bottom: 30px;
  }
   .title-cover {
    img {
      margin-top: 100px;
      @media screen and (max-width: 768px) {
        margin-top: 50px;
        width: 100%;
      }
    }
  }
  .box-voice {
    display: flex;
    justify-content: space-between;
    align-items: flex-start;
    @media screen and (max-width: 1024px) {
      flex-direction: column;
    }

    .box {
      width: 49%;
      @media screen and (min-width: 581px) and (max-width: 1024px) {
        width: 70%;
        margin-left: auto;
        margin-right: auto;
      }
      @media screen and (max-width: 580px) {
        width: 100%;
      }
    }
  }
}
</style>